const BrandCampaignSharePage = () => import('@/pages/brands/BrandCampaignSharePage.vue')

export default [
  {
    path: 'op/:offPlatformRelationshipId',
    name: 'personalizedCampaignShareScreen',
    component: BrandCampaignSharePage, 
    meta: {
      partialAuthPermitted: true,
    },
  },
  {
    path: ':brandCampaignId',
    name: 'campaignShareScreen',
    component: BrandCampaignSharePage,
    meta: {
      partialAuthPermitted: true,
    },
  },
]
