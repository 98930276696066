
import ParentPage from '@/pages/ParentPage.vue'

const AgencySelectPage = () => import('@/pages/agent/AgencySelectPage.vue')

const AgencyInvitePage = () => import('@/pages/agent/AgencyInvitePage.vue')

const AgentCampaignInvitePage = () => import('@/pages/agent/campaign/AgentCampaignInvitePage.vue')

const NewAgencyPage = () => import('@/pages/agent/NewAgencyPage.vue')

const AgencyPage = () => import('@/pages/agent/AgencyPage.vue')

const AgencyStatsPage = () => import('@/pages/agent/AgencyStatsPage.vue')

const AgencyAdminAgentsPage = () => import('@/pages/agent/AgencyAdminAgentsPage.vue')
const AgencyAdminWalletPage = () => import('@/pages/agent/AgencyAdminWalletPage.vue')
const AgencyAdminAgentPage = () => import('@/pages/agent/AgencyAdminAgentPage.vue')
const AgencySettingsPage = () => import('@/pages/agent/AgencySettingsPage.vue')

const AgencyCreatorsPage = () => import('@/pages/agent/creator/AgencyCreatorsPage.vue')
const AgentCreatorListsPage = () => import('@/pages/agent/creator/AgentCreatorListsPage.vue')
const AgentWalletPage = () => import('@/pages/agent/AgentWalletPage.vue')
const AgentSettingsPage = () => import('@/pages/agent/AgentSettingsPage.vue')

const AgencyCampaignListPage = () => import('@/pages/agent/campaign/AgencyCampaignListPage.vue')
const AgencyCampaignPage = () => import('@/pages/agent/campaign/AgencyCampaignPage.vue')
const AgencyCampaignOverviewPage = () => import('@/pages/agent/campaign/AgencyCampaignOverviewPage.vue')
const AgencyCampaignPermissionsPage = () => import('@/pages/agent/campaign/AgencyCampaignPermissionsPage.vue')
const AgencyCampaignCreatesPage = () => import('@/pages/agent/campaign/AgencyCampaignCreatesPage.vue')
const AgencyCampaignYourCreatorsPage = () => import('@/pages/agent/campaign/AgencyCampaignYourCreatorsPage.vue')
const AgencyCampaignDashboardsPage = () => import('@/pages/agent/campaign/AgencyCampaignDashboardsPage.vue')

const AgentCreatorPage = () => import('@/pages/agent/creator/AgentCreatorPage.vue')
const AgentCreatorOverviewPage = () => import('@/pages/agent/creator/AgentCreatorOverviewPage.vue')
const AgentCreatorMediaKitPage = () => import('@/pages/agent/creator/AgentCreatorMediaKitPage.vue')
const AgentCreatorCampaignsPage = () => import('@/pages/agent/creator/AgentCreatorCampaignsPage.vue')

const AgentCreatorCampaignRelationshipPage = () => import('@/pages/agent/creator/creatorCampaign/AgentCreatorCampaignPage.vue')
const AgentCreatorCampaignRelationshipOverviewPage = () => import('@/pages/agent/creator/creatorCampaign/AgentCreatorCampaignOverviewPage.vue')
const AgentCreatorCampaignRelationshipActivityPage = () => import('@/pages/agent/creator/creatorCampaign/AgentCreatorCampaignActivityPage.vue')

const AgentCreatorListPage = () => import('@/pages/agent/creator/AgentCreatorListPage.vue')
const AgencyAdminCreatorListsPage = () => import('@/pages/agent/creator/AgencyAdminCreatorListsPage.vue')


function getAgencyAgentCreatorCampaignRelationshipRoute(topRouteIsCampaign) {
  const campaignPathSegment = 'campaign/:brandCampaignId'
  const creatorPathSegment = 'creator/:creatorType(u)-:creatorUserId'

  return {
    path: `:agencyRole(agent|admin)/${topRouteIsCampaign ? campaignPathSegment : creatorPathSegment}/${topRouteIsCampaign ? creatorPathSegment : campaignPathSegment}`,
    name: topRouteIsCampaign ? 'agencyAgentCampaignCreator' : 'agencyAgentCreatorCampaign',
    component: AgentCreatorCampaignRelationshipPage,
    meta: {
      topRouteIsCampaign,
      navHighlightTabs: [topRouteIsCampaign ? 'agencyCampaigns' : 'agencyCreators'],
    },
    children: [
      {
        path: 'overview',
        name: topRouteIsCampaign ? 'agencyAgentCampaignCreatorOverview' : 'agencyAgentCreatorCampaignOverview',
        component: AgentCreatorCampaignRelationshipOverviewPage,
        meta: {
          creatorCampaignNavTitle: 'Overview',
        },
      },
      {
        path: 'activity',
        name: topRouteIsCampaign ? 'agencyAgentCampaignCreatorActivity' : 'agencyAgentCreatorCampaignActivity',
        component: AgentCreatorCampaignRelationshipActivityPage,
        meta: {
          creatorCampaignNavTitle: 'Activity',
        },
      },
    ],
  }
}


export default [
  {
    path: '',
    name: 'agentHome',
    component: AgencySelectPage,
    meta: {
      authRequired: true,
    },
  },
  {
    path: 'join/:inviteCode?',
    name: 'agencyInvite',
    component: AgencyInvitePage,
    meta: {
      authRequired: true,
    },
  },
  {
    path: 'create-org',
    name: 'newAgency',
    component: NewAgencyPage,
    meta: {
      authRequired: true,
    },
  },
  {
    path: 'campaign-collab/:inviteLinkId',
    name: 'agentCampaignInvite',
    component: AgentCampaignInvitePage,
    meta: {
      authRequired: true,
    },
  },
  {
    path: 'org/:agencyId',
    name: 'agency',
    component: AgencyPage,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: ':agencyRole(admin)',
        name: 'agencyAdminBase',
        component: ParentPage,
        redirect: { name: 'agencyAdminStats' },
        children: [
          {
            path: 'overview',
            name: 'agencyAdminStats',
            component: AgencyStatsPage,
          },
          {
            path: 'team',
            name: 'agencyTeam',
            component: AgencyAdminAgentsPage,
          },
          {
            path: 'lists',
            name: 'agencyAdminCreatorLists',
            component: AgencyAdminCreatorListsPage,
            redirect: { name: 'agencyAdminAgentCreatorLists' },
            children: [
              {
                path: 'by-agents',
                name: 'agencyAdminAgentCreatorLists',
              },
              {
                path: 'by-agency',
                name: 'agencyAdminAgencyCreatorLists',
              },
            ],
          },
          {
            path: 'earnings',
            name: 'agencyAdminWallet',
            component: AgencyAdminWalletPage,
          },
          {
            path: 'settings',
            name: 'agencyAdminSettings',
            component: AgencySettingsPage,
          },
        ],
      },
      {
        path: ':agencyRole(agent)',
        name: 'agencyAgentBase',
        component: ParentPage,
        redirect: { name: 'agencyAgentStats' },
        children: [
          {
            path: 'overview',
            name: 'agencyAgentStats',
            component: AgencyStatsPage,
          },
          {
            path: 'lists',
            name: 'agencyAgentCreatorLists',
            component: AgentCreatorListsPage,
          },
          {
            path: 'earnings',
            name: 'agencyAgentWallet',
            component: AgentWalletPage,
          },
          {
            path: 'settings',
            name: 'agencyAgentSettings',
            component: AgentSettingsPage,
          },
        ],
      },
      {
        path: ':agencyRole(agent|admin)/creators',
        name: 'agencyCreators',
        component: AgencyCreatorsPage,
      },
      {
        path: ':agencyRole(admin)/agent/:agentUserId',
        name: 'agencyAdminAgent',
        component: AgencyAdminAgentPage,
        meta: {
          navHighlightTabs: ['agencyTeam'],
        },
      },
      {
        path: ':agencyRole(admin)/list/:creatorListId',
        name: 'agencyAdminCreatorList',
        component: AgentCreatorListPage,
        meta: {
          navHighlightTabs: ['agencyAdminCreatorLists'],
        },
      },
      {
        path: ':agencyRole(agent|admin)/campaign/:brandCampaignId',
        name: 'agencyCampaign',
        component: AgencyCampaignPage,
        meta: {
          navHighlightTabs: ['agencyCampaigns'],
        },
        children: [
          {
            path: 'overview',
            name: 'agencyCampaignOverview',
            component: AgencyCampaignOverviewPage,
            meta: {
              campaignNavTitle: 'Overview',
            },
          },
          {
            path: 'permissions',
            name: 'agencyCampaignPermissions',
            component: AgencyCampaignPermissionsPage,
            meta: {
              campaignNavTitle: 'Permissions',
            },
          },
          {
            path: 'creates',
            name: 'agencyCampaignCreates',
            component: AgencyCampaignCreatesPage,
            meta: {
              campaignNavTitle: 'Creates',
            },
            redirect: { name: 'agencyCampaignAllCreates' },
            children: [
              {
                path: 'all',
                name: 'agencyCampaignAllCreates',
                meta: {
                  campaignCreatesTabTitle: 'All',
                },
              },
              {
                path: 'live',
                name: 'agencyCampaignLiveCreates',
                meta: {
                  campaignCreatesTabTitle: 'Live',
                },
              },
              {
                path: 'pending',
                name: 'agencyCampaignPendingCreates',
                meta: {
                  campaignCreatesTabTitle: 'Pending',
                },
              },
              {
                path: 'canceled',
                name: 'agencyCampaignCanceledCreates',
                meta: {
                  campaignCreatesTabTitle: 'Canceled',
                },
              },
            ],
          },
          {
            path: 'your-creators',
            name: 'agencyCampaignYourCreators',
            component: AgencyCampaignYourCreatorsPage,
            meta: {
              campaignNavTitle: 'Your Creators',
            },
          },
          {
            path: 'metrics',
            name: 'agencyCampaignMetrics',
            meta: {
              campaignNavTitle: 'Metrics',
            },
          },
          {
            path: 'dashboards',
            name: 'agencyCampaignDashboards',
            component: AgencyCampaignDashboardsPage,
            meta: {
              campaignNavTitle: 'Dashboards',
            },
          },
        ],
      },
      {
        path: ':agencyRole(agent|admin)/campaigns',
        name: 'agencyCampaigns',
        component: AgencyCampaignListPage,
        redirect: { name: 'agencyActiveCampaigns' },
        children: [
          {
            path: 'active',
            name: 'agencyActiveCampaigns',
          },
          {
            path: 'archived',
            name: 'agencyArchivedCampaigns',
          },
          {
            path: 'opportunities',
            name: 'agencyMarketplaceCampaigns',
          },
        ],
      },
      {
        path: ':agencyRole(agent|admin)/agent/:agentUserId/creator/:creatorType(u|p)-:creatorUserId',
        name: 'agencyCreator',
        component: AgentCreatorPage,
        meta: {
          navHighlightTabs: ['agencyCreators'],
        },
        redirect: { name: 'agencyCreatorOverview' },
        children: [
          {
            path: 'overview',
            name: 'agencyCreatorOverview',
            component: AgentCreatorOverviewPage,
            meta: {
              creatorNavTitle: 'Overview',
              supportedCreatorTypeList: ['u'],
            },
          },
          {
            path: 'media-kit',
            name: 'agencyCreatorMediaKit',
            component: AgentCreatorMediaKitPage,
            meta: {
              creatorNavTitle: 'Media Kit',
            },
          },
          {
            path: 'campaigns',
            name: 'agencyCreatorCampaigns',
            component: AgentCreatorCampaignsPage,
            meta: {
              creatorNavTitle: 'Campaigns',
              supportedCreatorTypeList: ['u'],
            },
            redirect: { name: 'agencyCreatorAppliedCampaigns' },
            children: [
              {
                path: 'submittedApplications',
                name: 'agencyCreatorAppliedCampaigns',
              },
              {
                path: 'currentOffers',
                name: 'agencyCreatorUnappliedCampaigns',
              },
            ],
          },
        ],
      },
      {
        path: ':agencyRole(agent)/list/:creatorListId',
        name: 'agencyAgentCreatorList',
        component: AgentCreatorListPage,
        meta: {
          navHighlightTabs: ['agencyAgentCreatorLists'],
        },
      },
      getAgencyAgentCreatorCampaignRelationshipRoute(true),
      getAgencyAgentCreatorCampaignRelationshipRoute(false),
    ],
  },
]
