import Vue from 'vue'
import Router from 'vue-router'
import { makeReachUrl } from '@happstv/shared/util/makeReachUrl'

import authRoutes from '@/router/authRoutes'
import settingsRoutes from '@/router/settingsRoutes'
import resourceRoutes from '@/router/resourceRoutes'
import consoleRoutes from '@/router/consoleRoutes'
import connectRoutes from '@/router/connectRoutes'
import campaignRoutes from '@/router/campaignRoutes'
import joinRoutes from '@/router/joinRoutes'
import paymentsRoutes from '@/router/paymentsRoutes'
import dashboardRoutes from '@/router/dashboardRoutes'
import agentRoutes from '@/router/agentRoutes'
import talentRoutes from '@/router/talentRoutes'
import talentListRoutes from '@/router/talentListRoutes'

import ParentPage from '@/pages/ParentPage.vue'

const ProfileNotFound = () => import('@/pages/ProfileNotFound.vue')

const { hostname } = window.location

const consoleOnly = ['console.reach.me', 'console.reach-dev.me'].includes(hostname)
const publicOnly = ['reach.me', 'reach-dev.me'].includes(hostname)
const connectOnly = ['connect.reach.me', 'connect.reach-dev.me'].includes(hostname)
const joinOnly = ['join.reach.me', 'join.reach-dev.me'].includes(hostname)
const campaignOnly = ['campaign.reach.me', 'campaign.reach-dev.me'].includes(hostname)
const paymentsOnly = ['payments.reach.me', 'payments.reach-dev.me'].includes(hostname)
const dashboardOnly = ['dashboard.reach.me', 'dashboard.reach-creators.com', 'dashboard.reach-dev.me'].includes(hostname)

const agentOnly = ['agent.reach.me', 'agent.reach-dev.me', 'app.reach.me', 'app.reach-dev.me'].includes(hostname)

const routes = [
  {
    path: 'redirect',
    name: 'redirect',
    beforeEnter(to) {
      const { url } = to.query
      window.location.href = url
    },
  },
  {
    path: 'route/:name',
    name: 'route',
    component: ParentPage,
    /**
     * I'm not sure why this beforeEnter hook doesn't work.
     * It really should be here, but since this didn't work
     * I moved it down to the `beforeEach` router hook below.
     * -Adam

    beforeEnter() {
      
      const { name } = to.params
      let query = {}
      let params = {}
      try {
        query = JSON.parse(to.query.query)
        // eslint-disable-next-line no-empty
      } catch(_){}
      try {
        params = JSON.parse(to.query.params)
        // eslint-disable-next-line no-empty
      } catch(_){}
      console.log({ name, query, params })
      return { name, query, params }
      return { name: 'home' }
    },
    */
  },
]

if (consoleOnly) {
  routes.push(...authRoutes)
  routes.push(...settingsRoutes)
  routes.push(...resourceRoutes)
  routes.push({
    path: '',
    name: 'home',
    redirect: { name: 'consoleHome' },
    component: ParentPage,
    children: consoleRoutes,
  })
} else if (connectOnly) {
  routes.push({
    path: '',
    name: 'home',
    redirect: { name: '404' },
    component: ParentPage,
    children: connectRoutes,
  })
} else if (joinOnly) {
  routes.push(...resourceRoutes)
  routes.push({
    path: '',
    name: 'home',
    redirect: { name: 'redirect', query: { url: 'https://about.reach.me' } },
    component: ParentPage,
    children: joinRoutes,
  })
} else if (campaignOnly) {
  routes.push(...resourceRoutes)
  routes.push({
    path: '',
    name: 'home',
    redirect: { name: 'redirect', query: { url: 'https://about.reach.me' } },
    component: ParentPage,
    children: campaignRoutes,
  })
} else if (paymentsOnly) {
  routes.push(...resourceRoutes)
  routes.push({
    path: '',
    name: 'home',
    redirect: { name: 'redirect', query: { url: 'https://about.reach.me' } },
    component: ParentPage,
    children: paymentsRoutes,
  })
} else if (dashboardOnly) {
  routes.push({
    path: '',
    name: 'home',
    redirect: { name: 'redirect', query: { url: 'https://about.reach.me' } },
    component: ParentPage,
    children: dashboardRoutes,
  })
} else if (agentOnly) {
  routes.push(...authRoutes)
  routes.push(...settingsRoutes)
  routes.push(...resourceRoutes)
  routes.push({
    path: '',
    name: 'home',
    redirect: { name: 'agentHome' },
    component: ParentPage,
    children: [
      ...authRoutes,
      ...settingsRoutes,
      ...resourceRoutes,
      ...agentRoutes,
    ],
  })
} else if (publicOnly) {
  routes.push({
    path: '',
    name: 'home',
    redirect: { name: 'redirect', query: { url: 'https://about.reach.me' } },
    component: ParentPage,
  })
  routes.push({
    path: 'talent',
    component: ParentPage,
    redirect: { name: 'redirect', query: { url: 'https://about.reach.me' } },
    children: talentRoutes,
  })
  routes.push({
    path: 'talent-list',
    component: ParentPage,
    redirect: { name: 'redirect', query: { url: 'https://about.reach.me' } },
    children: talentListRoutes,
  })
} else {
  routes.push({
    path: '',
    name: 'home',
    redirect: { name: 'redirect', query: { url: 'https://about.reach.me' } },
    component: ParentPage,
  })
  routes.push(...authRoutes)
  routes.push(...settingsRoutes)
  routes.push(...resourceRoutes)
  routes.push({
    path: 'console',
    component: ParentPage,
    redirect: { name: 'consoleHome' },
    children: consoleRoutes,
  })
  routes.push({
    path: 'connect',
    redirect: { name: '404' },
    component: ParentPage,
    children: connectRoutes,
  })
  routes.push({
    path: 'join',
    redirect: { name: 'redirect', query: { url: makeReachUrl() } },
    component: ParentPage,
    children: joinRoutes,
  })
  routes.push({
    path: 'campaign',
    redirect: { name: 'redirect', query: { url: makeReachUrl() } },
    component: ParentPage,
    children: campaignRoutes,
  })
  routes.push({
    path: 'payments',
    redirect: { name: 'redirect', query: { url: makeReachUrl() } },
    component: ParentPage,
    children: paymentsRoutes,
  })
  routes.push({
    path: 'dashboard',
    redirect: { name: 'redirect', query: { url: makeReachUrl() } },
    component: ParentPage,
    children: dashboardRoutes,
  })
  routes.push({
    path: 'agent',
    redirect: { name: 'redirect', query: { url: makeReachUrl() } },
    component: ParentPage,
    children: agentRoutes,
  })
  routes.push({
    path: 'talent',
    redirect: { name: 'redirect', query: { url: makeReachUrl() } },
    component: ParentPage,
    children: talentRoutes,
  })
  routes.push({
    path: 'talent-list',
    redirect: { name: 'redirect', query: { url: makeReachUrl() } },
    component: ParentPage,
    children: talentListRoutes,
  })
}

routes.push({
  path: '404',
  name: '404',
  component: ProfileNotFound,
})

routes.push({
  path: '*',
  redirect: { name: 'home' },
})

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes.map(route => ({ ...route, path: `/${route.path}` })),
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  },
})

// set this to true while calling router.push to avoid all `beforeEach` checks
router.isForcedRedirect = false

// set this to true while calling router.push to avoid `beforeEach` opening a redirect in a new tab
router.isAllowedRedirect = false

const { push, replace } = router

const normalPushHandler = async (...args) => {
  try {
    router.$store.commit('overlays/CLOSE_ALL_FULLSCREEN_POPUPS', { skipPageless: true })
    await push.call(router, ...args)
  } catch (error) {
    if (!Router.isNavigationFailure(error)) throw error
  }
}

let replacing = false
const normalReplaceHandler = async (...args) => {
  try {
    router.$store.commit('overlays/CLOSE_ALL_FULLSCREEN_POPUPS', { skipPageless: true })
    replacing = true
    await replace.call(router, ...args)
  } catch(error) {
    if (!Router.isNavigationFailure(error)) throw error
  }
}

router.push = normalPushHandler
router.pushOverrideAllowed = async (...args) => {
  router.isAllowedRedirect = true
  await normalPushHandler(...args)
  router.isAllowedRedirect = false
}

router.replace = normalReplaceHandler
router.replaceOverrideAllowed = async (...args) => {
  router.isAllowedRedirect = true
  await normalReplaceHandler(...args)
  router.isAllowedRedirect = false
}

let goingBack = false
const pushHistory = []

router.goBack = async () => {
  goingBack = true
  router.isAllowedRedirect = true
  const { currentRoute } = router
  const route = pushHistory.pop()
    || router.defaultBackRoute
    || currentRoute.meta.defaultBackRoute
    || (currentRoute.matched.some(({ meta }) => meta.useHeirarchyForGoBack) && [...currentRoute.matched].reverse().slice(1).filter(({ redirect }) => !redirect || !currentRoute.matched.some(({ name }) => name === redirect.name))[0])
    || currentRoute.matched.reduce((prev, item) => item.meta.defaultBackRoute || prev, undefined)
  await push.apply(router, [
    route || { name: 'home' },
  ]).catch((error) => {
    if (!Router.isNavigationFailure(error)) throw error
  })
  router.isAllowedRedirect = false
  goingBack = false

  return Boolean(route)
}

let nextRoute

router.beforeEach(async (to, from, next) => {
  if (to.name === 'route') { // see the "name: 'route'" route above for a note on this block
    const { name } = to.params
    let query = {}
    let params = {}
    try {
      query = JSON.parse(to.query.query)
      // eslint-disable-next-line no-empty
    } catch(_){}
    try {
      params = JSON.parse(to.query.params)
      // eslint-disable-next-line no-empty
    } catch(_){}

    next({ name, query, params })
    return
  }

  if (from && from.name) nextRoute = to

  if (router.isForcedRedirect) {
    next(true)
    return
  }

  const fromMatched = (from || {}).matched || []
  const toMatched = (to || {}).matched || []

  if (fromMatched.some(({ name, meta }, i) => {
    if (!meta.blockAllRedirects || router.isAllowedRedirect) return false

    if (toMatched.length <= i) return true
    return toMatched[i].name !== name
  })) {
    window.open(router.resolve(to).href, '_blank')
    next(false)
    replacing = false
    return
  }

  if (router.$store.state.overlays.fullscreenPopups.filter(({ pageless }) => !pageless).length) {
    router.$store.dispatch('overlays/REMOVE_FULLSCREEN_POPUP_AT_INDEX', router.$store.state.overlays.fullscreenPopups.length - 1)
    next(false)
    replacing = false
    return
  }

  router.$store.commit('SET_NAVIGATING_TO_ROUTE', true)

  const redirect = await router.$store.dispatch('GET_REDIRECT_FROM_ROUTE_IF_NECESSARY', { route: to })

  next(redirect)
  if (redirect === false) {
    // because afterEach won't be called:
    replacing = false
    router.$store.commit('SET_NAVIGATING_TO_ROUTE', false)
  }
})

router.afterEach((to, from) => {
  if (!goingBack && !replacing && (from || {}).name && !to.matched.some(({ meta }) => meta.useHeirarchyForGoBack)) pushHistory.push(from)
  replacing = false

  router.$store.commit('SET_NAVIGATING_TO_ROUTE', false)
  router.$store.commit('SET_CURRENT_ROUTE', to)

  router.$store.dispatch('analytics/LOG_EVENT', { name: 'navigated_to_route' })
})

router.reload = () => {
  router.load(router.currentRoute)
}

router.load = (route) => {
  const { href } = router.resolve(route)
  if (href === window.location.pathname) window.location.reload(true)
  else window.location.replace(href)
}

router.onError(() => {
  if (nextRoute) router.load(nextRoute)
})

export default router
