import PublicMediaKitPage from '@/pages/agent/public/PublicMediaKitPage.vue'

export default [
  {
    path: ':mediaKitId',
    name: 'publicMediaKit',
    component: PublicMediaKitPage,
    meta: {
      noTopNav: true,
      authRequired: false,
      lightTheme: true,
    },
  },
]
