import Vue from 'vue'

import {
  cloudTimestamp,
  watchDoc,
  watchCol,
  addDoc,
  updateDoc,
} from '@happstv/shared/util/firebase/firestoreUtils'

import {
  DM_CONVERSATIONS_COL_PATH,
  DM_CONVERSATION_DOC_PATH,
  DM_CHATS_COL_PATH,
} from '@happstv/shared/util/firebase/firestorePaths'

const initConversationsState = () => ({
  dmConversationIdList: undefined,
  dmConversationsById: {},
  dmConversationsQueryLimit: 10,
  dmConversationsUnsubscribe: undefined,
})

const initChatsState = () => ({
  dmConversationUnsubscribesById: {},
  dmChatIdList: undefined,
  dmChatsById: {},
  dmChatsQueryLimit: 30,
  dmChatsUnsubscribe: undefined,
})

const initState = () => ({
  ...initConversationsState(),
  ...initChatsState(),
})

export default {
  namespaced: true,

  state: {
    ...initState(),
  },

  mutations: {
    reinitConversations(state) {
      if (state.dmConversationsUnsubscribe) state.dmConversationsUnsubscribe()
      Object.assign(state, initConversationsState())
    },
    reinitChats(state) {
      if (state.dmChatsUnsubscribe) state.dmChatsUnsubscribe()
      Object.values(state.dmConversationUnsubscribesById).forEach(u => u())
      Object.assign(state, initChatsState())
    },

    setDmConversationIdList(state, value) {
      state.dmConversationIdList = value
    },
    setDmConversationById(state, conversation) {
      Vue.set(state.dmConversationsById, conversation.id, conversation)
    },
    setDmConversationsUnsubscribe(state, value) {
      state.dmConversationsUnsubscribe = value
    },
    setDmConversationsQueryLimit(state, value) {
      state.dmConversationsQueryLimit = value
    },
    setDmConversationUnsubscribeById(state, { id, unsubscribe }) {
      Vue.set(state.dmConversationUnsubscribesById, id, unsubscribe)
    },
    setDmChatIdList(state, value) {
      state.dmChatIdList = value
    },
    setDmChatById(state, chat) {
      Vue.set(state.dmChatsById, chat.id, chat)
    },
    setDmChatsUnsubscribe(state, value) {
      state.dmChatsUnsubscribe = value
    },
    setDmChatsQueryLimit(state, value) {
      state.dmChatsQueryLimit = value
    },
  },

  actions: {
    watchMoreDmConversations({ state, rootState, commit }) {
      const oldDmConversationIdList = state.dmConversationIdList
      const oldDmConversationsQueryLimit = state.dmConversationsQueryLimit
      if (oldDmConversationIdList !== undefined && oldDmConversationIdList.length < oldDmConversationsQueryLimit) return

      const oldUnsubscribe = state.dmConversationsUnsubscribe
      if (oldUnsubscribe) oldUnsubscribe()

      const { signedInUserId } = rootState
      if (!signedInUserId) return

      const dmConversationsQueryLimit = oldDmConversationsQueryLimit + 10
      commit('setDmConversationsQueryLimit', dmConversationsQueryLimit)

      const dmConversationsUnsubscribe = watchCol(DM_CONVERSATIONS_COL_PATH, q => q.orderBy('latestActivityDate', 'desc').where('userIdList', 'array-contains', signedInUserId).limit(dmConversationsQueryLimit), (conversationsSnap) => {
        conversationsSnap.docChanges()
          .filter(({ newIndex }) => newIndex !== -1)
          .forEach(({ doc }) => {
            const conversation = { id: doc.id, ...(doc.data() || {}) }
            commit('setDmConversationById', conversation)
          })

        const dmConversationIdList = conversationsSnap.docs.map(({ id }) => id)
        commit('setDmConversationIdList', dmConversationIdList)
      })

      commit('setDmConversationsUnsubscribe', dmConversationsUnsubscribe)
    },

    watchDmConversation({ state, commit }, dmConversationId) {
      const oldUnsubscribe = state.dmConversationUnsubscribesById[dmConversationId]
      if (oldUnsubscribe) oldUnsubscribe()

      const unsubscribe = watchDoc(DM_CONVERSATION_DOC_PATH(dmConversationId), (dmConversationDoc) => {
        const conversation = { id: dmConversationDoc.id, ...(dmConversationDoc.data() || {}) }
        commit('setDmConversationById', conversation)
      })
      commit('setDmConversationUnsubscribeById', { id: dmConversationId, unsubscribe })
    },

    watchMoreDmChats({ state, rootState, commit }, dmConversationId) {
      const oldDmChatIdList = state.dmChatIdList
      const oldDmChatsQueryLimit = state.dmChatsQueryLimit
      if (oldDmChatIdList !== undefined && oldDmChatIdList.length < oldDmChatsQueryLimit) return

      const oldUnsubscribe = state.dmChatsUnsubscribe
      if (oldUnsubscribe) oldUnsubscribe()

      const { signedInUserId } = rootState
      if (!signedInUserId) return

      const dmChatsQueryLimit = oldDmChatsQueryLimit + 30
      commit('setDmChatsQueryLimit', dmChatsQueryLimit)

      const dmChatsUnsubscribe = watchCol(DM_CHATS_COL_PATH, q => q.orderBy('sendDate', 'desc').where('dmConversationId', '==', dmConversationId).limit(dmChatsQueryLimit), (chatsSnap) => {
        chatsSnap.docChanges()
          .filter(({ newIndex }) => newIndex !== -1)
          .forEach(({ doc }) => {
            const chat = { id: doc.id, ...(doc.data() || {}) }
            commit('setDmChatById', chat)
          })

        const dmChatIdList = chatsSnap.docs.map(({ id }) => id)
        commit('setDmChatIdList', dmChatIdList)
      })

      commit('setDmChatsUnsubscribe', dmChatsUnsubscribe)
    },

    async updateLatestReadDate({ rootState }, { dmConversationId, date }) {
      if (!dmConversationId) return { success: false, error: 'No chat conversation ID specified.' }
      if (!date) return { success: false, error: 'No date specified.' }

      const { signedInUserId } = rootState
      if (!signedInUserId) return { success: false, error: 'Invalid authentication.' }

      await updateDoc(DM_CONVERSATION_DOC_PATH(dmConversationId), {
        [`latestReadDateByUserId.${signedInUserId}`]: date,
      })
    },

    async sendChat({ rootState }, { dmConversationId, text, image, video }) {
      if (!dmConversationId) return { success: false, error: 'No chat conversation ID specified.' }
      if (!text && !image && !video) return { success: false, error: 'No chat content specified.' }

      const { signedInUserId } = rootState
      if (!signedInUserId) return { success: false, error: 'Invalid authentication.' }

      const chat = {
        sendDate: cloudTimestamp(),
        dmConversationId,
        originUserId: signedInUserId,
      }

      if (text) Object.assign(chat, { text })
      if (image) Object.assign(chat, { image })
      if (video) Object.assign(chat, { video })

      const chatRef = await addDoc(DM_CHATS_COL_PATH, chat)
      return { success: true, dmChatId: chatRef.id }
    },
  },
}
