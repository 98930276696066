//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

import { waitPromise } from '@happstv/shared/util/utils'

import performCloudFunction from '@/util/firebase/performCloudFunction'

import TextInput from '@/components/universal/input/string/TextInput.vue'
import PillButton from '@/components/universal/basic/PillButton.vue'
import SwitchInput from '@/components/universal/input/primitive/SwitchInput.vue'
import Tooltip from '@/components/universal/overlapping/Tooltip.vue'
import PlatformIcon from '@/components/connectedAccounts/PlatformIcon.vue'

export default {
  name: 'ExternalAuthPage',
  components: {
    TextInput,
    PillButton,
    SwitchInput,
    Tooltip,
    PlatformIcon,
  },
  data() {
    return {
      error: this.$route.query.error,
      success: undefined,
      destinationsToSelect: undefined,
      savingSelectedDestinations: false,

      username: '',
      password: '',
      verifying: false,

      attemptingReactNativeClose: false,
      closeMyself: undefined,
    }
  },
  computed: {
    ...mapState('connectedAccounts', [
      'externalPlatformsInfo',
    ]),

    platformId() {
      return this.$route.params.platformId
    },
    platformInfo() {
      const { externalPlatformsInfo = {}, platformId } = this
      return externalPlatformsInfo[platformId] || {}
    },
    authType() {
      return this.platformInfo.authType
    },
    platformInvalid() {
      const { platformId, externalPlatformsInfo } = this
      if (!platformId) return true
      if (!externalPlatformsInfo) return false
      return !externalPlatformsInfo[platformId]
    },
    platformName() {
      return this.platformInfo.platformName || '[Unknown]'
    },
    inLaunch() {
      if (this.platformInvalid) return false
      return this.$route.name === 'externalAuthLaunch'
    },
    inLogin() {
      if (this.platformInvalid) return false
      return this.$route.name === 'externalAuthLogin'
    },
    inReturn() {
      if (this.platformInvalid) return false
      return this.$route.name === 'externalAuthReturn'
    },
    inIframe() {
      if (this.platformInvalid) return false
      return this.$route.name === 'externalAuthIframe'
    },
    statusLabel() {
      const {
        error,
        success,
        platformInvalid,
        platformName,
        inLaunch,
        inLogin,
        inReturn,
        externalPlatformsInfo,
      } = this

      if (success) return success.replace(/{{platformName}}/g, platformName)
      if (error) return error.replace(/{{platformName}}/g, platformName)

      if (!externalPlatformsInfo) {
        return 'Loading platform info...'
      }

      if (platformInvalid) {
        return 'Unknown platform. Please try again.'
      }

      if (inLaunch) {
        return `Connecting to ${platformName}...`
      }

      if (inLogin) {
        return 'Please log in'
      }

      if (inReturn) {
        return 'Processing...'
      }

      return 'Unknown state. Please try again.'
    },
    selectedDestinations() {
      return (this.destinationsToSelect || []).filter(destination => destination.multicastingEnabled)
    },
    iframeUrl() {
      const { iframeUrl } = this.platformInfo
      if (iframeUrl) {
        const { search, hash } = window.location
        return `${iframeUrl}${search}${hash}`
      }

      return undefined
    },
  },
  watch: {
    inReturn: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && !oldValue) this.verifyAuthRequest()
      },
    },
    error: {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          await this.$nextTick() // for "returned" event to happen

          this.$store.dispatch('analytics/LOG_EVENT', {
            name: 'external_platform_auth_return_failed',
            data: {
              platform: this.platformId,
              errorMessage: newValue,
            },
          })
        }
      },
    },
  },
  methods: {
    async verifyAuthRequest() {
      this.verifying = true

      const { platformId, username, password } = this
      const { query = {}, hash = '' } = this.$route

      this.$store.dispatch('analytics/LOG_EVENT', {
        name: 'external_platform_auth_returned',
        data: {
          platform: platformId,
        },
      })

      const verificationResult = await performCloudFunction('verifyExternalPlatformAuthRequest', { platform: platformId, query, hash, username, password })

      if (!verificationResult.success) {
        this.error = verificationResult.error || 'Connection failed.'
        this.verifying = false
        return
      }

      const { accountInfo = {}, destinations = [] } = verificationResult

      if (destinations.length > 0) {
        this.success = 'Select {{platformName}} accounts to connect:'
        this.destinationsToSelect = destinations
          .map(destination => ({ ...destination, multicastingEnabled: destination.isProfile }))
      } else {
        this.success = `${accountInfo.displayName || '{{platformName}}'} is now connected!`

        this.attemptReactNativeClose()
      }

      this.verifying = false
    },
    async saveSelectedDestinations() {
      const { platformId } = this
      const { query, hash } = this.$route

      const { selectedDestinations } = this

      this.savingSelectedDestinations = true

      const saveResult = await performCloudFunction('saveAuthRequestDestinations', {
        platform: platformId,
        query,
        hash,
        destinationIds: selectedDestinations.map(({ id }) => id),
      })

      this.savingSelectedDestinations = false

      this.destinationsToSelect = undefined

      if (!saveResult.success) {
        this.error = saveResult.error || 'Failed to connect additinal accounts.'
        return
      }

      this.success = `${selectedDestinations.length} accounts are now connected!`

      this.attemptReactNativeClose()
    },
    async attemptReactNativeClose() {
      const { ReactNativeWebView } = window
      if (ReactNativeWebView) {
        this.attemptingReactNativeClose = true

        await waitPromise(1000)

        ReactNativeWebView.postMessage('closeMyself')

        await waitPromise(1000)

        this.attemptingReactNativeClose = false
      }
    },
    receiveMessage({ data }) {
      if (data === 'closeMyselfIsAvailable') {
        this.closeMyself = () => window.opener.postMessage('closeMyself', '*')
      }
      if (data === 'closeMyselfIsAvailableIsStarlight') {
        this.isStarlight = true
      }
    },
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)

    const { opener } = window
    if (opener) opener.postMessage('isCloseMyselfAvailable', '*')
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
}
