
import { detect } from 'detect-browser'

export default function getDeviceInfo() {
  const {
    userAgent,
  } = navigator

  const browser = detect()
  const browserName = browser ? [
    browser.name && `${browser.name[0].toUpperCase()}${browser.name.substring(1)}`,
    browser.version && `(v${browser.version})`,
  ].filter(item => item).join(' ') : null

  const osName = browser.os || null

  return {
    name: browserName,
    app: 'Reach Website',
    browserName,
    osName,
    userAgent,
  }
}
