var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PublicPageFrame', {
    attrs: {
      "showFooter": false
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var columnWidth = _ref.columnWidth;
        return [_c('div', {
          attrs: {
            "id": "topFlexer"
          }
        }), !_vm.inviteUser ? [_c('LoadingSpinner')] : [_c('ik-image', {
          attrs: {
            "path": _vm.imageSrc,
            "transformation": [{
              width: Math.min(300, columnWidth),
              height: Math.min(300, columnWidth)
            }],
            "alt": "User profile photo",
            "id": "profilePhoto"
          }
        }), _c('h1', [_vm._v(_vm._s(_vm.inviteUser.waitlistWebTitle || "".concat(_vm.inviteUser.fullName, " wants to collab")))]), _c('p', [_vm._v(_vm._s(_vm.inviteUser.waitlistWebDescription || 'To collaborate and discover other partnership opportunities, download and join Reach.'))]), _c('div', {
          staticClass: "appBadges"
        }, [_c('a', {
          attrs: {
            "href": _vm.appStoreDynamicLink
          },
          on: {
            "click": function click($event) {
              return _vm.LOG_EVENT({
                name: 'app_store_button_clicked'
              });
            }
          }
        }, [_c('AppleBadge')], 1), _c('a', {
          attrs: {
            "href": _vm.googlePlayDynamicLink
          },
          on: {
            "click": function click($event) {
              return _vm.LOG_EVENT({
                name: 'google_play_button_clicked'
              });
            }
          }
        }, [_c('GoogleBadge')], 1)])]];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }