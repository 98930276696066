
import Vue from 'vue'
import { watchColGroup, getColGroup } from '@happstv/shared/util/firebase/firestoreUtils'
import { EXTERNAL_PLATFORM_ACCOUNTS_GROUP_NAME } from '@happstv/shared/util/firebase/firestorePaths'
import { TIKTOK_NATIVE, INSTAGRAM_NATIVE } from '@happstv/shared/util/multicastingConstants'

const initState = () => ({
  connectedAccountListByUserId: {},
  connectedAccountListUnsubscribeByUserId: {},
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    REINIT(state) {
      Object.values(state.connectedAccountListUnsubscribeByUserId).forEach(u => u())
      Object.assign(state, initState())
    },
    SET_CONNECTED_ACCOUNT_LIST(state, { userId, connectedAccountList }) {
      Vue.set(state.connectedAccountListByUserId, userId, connectedAccountList)
    },
    SET_CONNECTED_ACCOUNT_LIST_UNSUBSCRIBE(state, { userId, unsubscribe }) {
      state.connectedAccountListUnsubscribeByUserId[userId]?.()
      Vue.set(state.connectedAccountListUnsubscribeByUserId, userId, unsubscribe)
    },
  },

  actions: {
    PROCESS_CONNECTED_ACCOUNT_LIST({ commit }, { accountsSnap, userId, topPlatform }) {
      const connectedAccountList = (accountsSnap.docs || [])
        .map(accountDoc => ({ id: accountDoc.id, ...(accountDoc.data() || {}) }))
        .filter(({ platform }) => [TIKTOK_NATIVE, INSTAGRAM_NATIVE].includes(platform))
        .sort((a, b) => {
          // If topPlatform is set, put accounts with that platform at the top
          if (!topPlatform) return (b.medianViewCount || 0) - (a.medianViewCount || 0) || (b.averageViewCount || 0) - (a.averageViewCount || 0) || (b.followerCount || 0) - (a.followerCount || 0) || a.id.localeCompare(b.id)
          const getPlatformValue = account => account.platform === topPlatform ? 1 : 0
          return getPlatformValue(b) - getPlatformValue(a)
        })

      commit('SET_CONNECTED_ACCOUNT_LIST', { connectedAccountList, userId })
    },
    async FETCH_CONNECTED_ACCOUNT_LIST({ dispatch }, userId) {
      const accountsSnap = await getColGroup(EXTERNAL_PLATFORM_ACCOUNTS_GROUP_NAME, q => q.where('userId', '==', userId))
      dispatch('PROCESS_CONNECTED_ACCOUNT_LIST', { accountsSnap, userId })
    },
    WATCH_CONNECTED_ACCOUNT_LIST({ commit, dispatch }, { userId, topPlatform }) {
      const unsubscribe = watchColGroup(EXTERNAL_PLATFORM_ACCOUNTS_GROUP_NAME, q => q.where('userId', '==', userId), (accountsSnap) => {
        dispatch('PROCESS_CONNECTED_ACCOUNT_LIST', { accountsSnap, userId, topPlatform })
      })
      commit('SET_CONNECTED_ACCOUNT_LIST_UNSUBSCRIBE', { userId, unsubscribe })
    },
  },
}
