
import { TIKTOK_NATIVE, INSTAGRAM_NATIVE, YOUTUBE_NATIVE, SNAPCHAT_NATIVE } from '@happstv/shared/util/multicastingConstants'

import TiktokIcon from '@/assets/svg/social/external/TiktokIcon.vue'
import InstagramIcon from '@/assets/svg/social/external/InstagramIconWhite.vue'
import YoutubeIcon from '@/assets/svg/social/external/YoutubeIcon.vue'
import SnapchatIcon from '@/assets/svg/social/external/SnapchatIcon.vue'


export const getAccountUsernameRegex = (platform, domainRequired) => {
  switch (platform) {
    case TIKTOK_NATIVE:
      return new RegExp(`^(?:(?:https:\\/\\/)?(?:www\\.)?tiktok\\.com\\/@)${domainRequired ? '' : '?'}([\\w\\d.]+)\\/?(?:\\?\\S*)?$`)
    case INSTAGRAM_NATIVE:
      return new RegExp(`^(?:(?:https:\\/\\/)?(?:www\\.)?instagram\\.com\\/)${domainRequired ? '' : '?'}([\\w\\d.]+)\\/?(?:\\?\\S*)?$`)
    case YOUTUBE_NATIVE:
      return new RegExp(`^(?:(?:https:\\/\\/)?(?:www\\.)?(?:m\\.)?youtube\\.com\\/)${domainRequired ? '' : '?'}((?:@|channel\\/|c\\/)?[\\w\\d.-]+)\\/?(?:\\?\\S*)?$`)
    case SNAPCHAT_NATIVE:
      return new RegExp(`^(?:(?:https:\\/\\/)?(?:www\\.)?snapchat\\.com\\/add\\/)${domainRequired ? '' : '?'}([\\w\\d.-]+)\\/?(?:\\?\\S*)?$`)
    default:
      return undefined
  }
}

export const getAccountUrl = (platform, username) => {
  if (!username) return undefined
  if (platform === TIKTOK_NATIVE) return `https://www.tiktok.com/@${username}/`
  if (platform === INSTAGRAM_NATIVE) return `https://www.instagram.com/${username}/`
  if (platform === YOUTUBE_NATIVE) return `https://www.youtube.com/${username}/`
  if (platform === SNAPCHAT_NATIVE) return `https://www.snapchat.com/add/${username}/`
  return undefined
}

export function parsePlatformAndUsernameFromProfileUrl(url = '') {
  const foundResult = [TIKTOK_NATIVE, INSTAGRAM_NATIVE, YOUTUBE_NATIVE, SNAPCHAT_NATIVE]
    .map((platform) => {
      const usernameRegex = getAccountUsernameRegex(platform, true)
      if (!usernameRegex) return undefined

      const [, username] = url.match(usernameRegex) || []
      if (!username) return undefined

      return { platform, username }
    })
    .find(result => result)

  return foundResult
}


export const getPostRegex = (platform) => {
  if (platform === TIKTOK_NATIVE) return /^(?:https:\/\/(?:www\.)?tiktok\.com\/@[\w\d.]+\/(?:video|photo)\/)?(\d+)\/?(?:\?\S*)?$/
  if (platform === INSTAGRAM_NATIVE) return /^(?:https:\/\/(?:www\.)?instagram\.com\/(?:reel|p)\/)?([a-zA-Z0-9_-]+)\/?(?:\?\S*)?$/
  return undefined
}

export const getSoundRegex = (platform) => {
  if (platform === TIKTOK_NATIVE) return /^(?:https:\/\/(?:www\.)?tiktok\.com\/music\/)?(?:[^/\s]+-)?(\d+)\/?(?:\?\S*)?$/
  if (platform === INSTAGRAM_NATIVE) return /^(?:https:\/\/(?:www\.)?instagram\.com\/reels\/audio\/)?(?:[^/\s]+-)?(\d+)\/?(?:\?\S*)?$/
  return undefined
}

export const getSoundUrl = (platform, soundId) => {
  if (!soundId) return undefined
  if (platform === TIKTOK_NATIVE) return `https://www.tiktok.com/music/sound-${soundId}/`
  if (platform === INSTAGRAM_NATIVE) return `https://www.instagram.com/reels/audio/${soundId}/`
  return undefined
}

export function getPlatformIcon(platform) {
  if (platform) {
    if (platform === TIKTOK_NATIVE) return TiktokIcon
    if (platform === INSTAGRAM_NATIVE) return InstagramIcon
    if (platform === YOUTUBE_NATIVE) return YoutubeIcon
    if (platform === SNAPCHAT_NATIVE) return SnapchatIcon
  }
  return undefined
}

