const StripeConnectAccountConfirmation = () => import('@/pages/payments/StripeConnectAccountConfirmation.vue')
const StripeConnectAccountFailure = () => import('@/pages/payments/StripeConnectAccountFailure.vue')
const PaymentsSplashScreen = () => import('@/pages/payments/PaymentsSplashScreen.vue')
const PaymentsStripeLaunchPage = () => import('@/pages/payments/PaymentsStripeLaunchPage.vue')

export default [
  {
    path: '/',
    name: 'paymentsSplash',
    component: PaymentsSplashScreen,
    meta: {
      noSideItems: true,
      partialAuthPermitted: true,
    },
  },
  {
    path: 'confirmation',
    name: 'stripeConnectAccountConfirmation',
    component: StripeConnectAccountConfirmation,
    meta: {
      noSideItems: true,
      partialAuthPermitted: true,
    },
  },
  {
    path: 'return-failure',
    name: 'stripeConnectAccountFailure',
    component: StripeConnectAccountFailure,
    meta: {
      noSideItems: true,
      partialAuthPermitted: true,
    },
  },
  {
    path: 'stripe',
    name: 'paymentsStripe',
    component: PaymentsStripeLaunchPage,
    redirect: { name: 'paymentsSplash' },
    children: [
      {
        path: 'launch',
        name: 'paymentsStripeLaunch',
        meta: {
          noSideItems: true,
          partialAuthPermitted: true,
        },
      },
      {
        path: 'error',
        name: 'paymentsStripeError',
        meta: {
          noSideItems: true,
          partialAuthPermitted: true,
        },
      },
    ],
  },
]
