var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "externalAuthPage fullscreen"
  }, [_c('div', {
    staticClass: "safeArea",
    attrs: {
      "id": "externalAuthPageInner"
    }
  }, [_vm.inIframe ? [_vm.iframeUrl ? _c('iframe', {
    staticClass: "fillParent",
    attrs: {
      "src": _vm.iframeUrl
    }
  }) : _c('LoadingSpinner', {
    staticClass: "absolute center"
  })] : _c('div', [_c('PlatformIcon', _vm._b({
    attrs: {
      "id": "platformIcon"
    }
  }, 'PlatformIcon', {
    platformId: _vm.platformId
  }, false)), _c('h4', {
    class: {
      error: _vm.error && !_vm.success
    },
    attrs: {
      "id": "status"
    }
  }, [_vm._v(_vm._s(_vm.statusLabel))]), _vm.destinationsToSelect ? [_vm.selectedDestinations.length > 10 ? _c('p', {
    staticClass: "error"
  }, [_vm._v("Please select no more than 10 accounts.")]) : _vm._e(), _c('div', {
    attrs: {
      "id": "destinationsSelectFrame"
    }
  }, [_c('div', {
    staticClass: "darkestScrollbars",
    attrs: {
      "id": "destinationsSelect"
    }
  }, _vm._l(_vm.destinationsToSelect, function (destination) {
    return _c('div', {
      key: destination.id,
      staticClass: "destination",
      class: {
        disabled: destination.insufficientPermissions
      }
    }, [_c('SwitchInput', {
      staticClass: "switch",
      attrs: {
        "disabled": _vm.savingSelectedDestinations
      },
      model: {
        value: destination.multicastingEnabled,
        callback: function callback($$v) {
          _vm.$set(destination, "multicastingEnabled", $$v);
        },
        expression: "destination.multicastingEnabled"
      }
    }), _c('div', {
      staticClass: "destinationInfo"
    }, [_c('h4', [_vm._v(_vm._s(destination.displayName))]), destination.isPage ? _c('p', [_vm._v("Page You Manage")]) : destination.isGroup ? _c('p', [_vm._v("Group" + _vm._s(destination.administrator ? ' You Admin' : ''))]) : destination.isProfile ? _c('p', [_vm._v("Your Profile")]) : _vm._e()]), destination.insufficientPermissions ? _c('Tooltip', {
      staticClass: "tooltip fillParent",
      attrs: {
        "showOnHover": "",
        "horizontalOffset": "-20px",
        "triangleHorizontalOffset": "-72px",
        "verticalOffset": "0"
      }
    }, [_vm._v("You have insufficient permissions"), _c('br'), _vm._v("to connect to this account.")]) : _vm._e()], 1);
  }), 0)]), _c('PillButton', {
    attrs: {
      "id": "bottomButton",
      "loading": _vm.savingSelectedDestinations,
      "disabled": !_vm.selectedDestinations.length || _vm.selectedDestinations.length > 10
    },
    on: {
      "click": _vm.saveSelectedDestinations
    }
  }, [!_vm.selectedDestinations.length ? [_vm._v("No Accounts Selected")] : _vm.selectedDestinations.every(function (destination) {
    return destination.isProfile;
  }) ? [_vm._v("Just Connect My Profile")] : [_vm._v("Connect " + _vm._s(_vm.selectedDestinations.length) + " Account" + _vm._s(_vm.selectedDestinations.length === 1 ? '' : 's'))]], 2)] : _vm.inLogin && !_vm.success ? [_c('TextInput', {
    staticClass: "textInput",
    attrs: {
      "placeholder": "Username"
    },
    model: {
      value: _vm.username,
      callback: function callback($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _c('TextInput', {
    staticClass: "textInput",
    attrs: {
      "placeholder": "Password",
      "obscureValues": ""
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('PillButton', {
    attrs: {
      "large": "",
      "disabled": !_vm.username || !_vm.password,
      "loading": _vm.verifying
    },
    on: {
      "click": _vm.verifyAuthRequest
    }
  }, [_vm._v("Log In")])] : (_vm.error || _vm.success) && !_vm.attemptingReactNativeClose ? [_vm.closeMyself ? _c('PillButton', {
    attrs: {
      "id": "bottomButton",
      "large": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeMyself();
      }
    }
  }, [_vm._v("Close This Window")]) : _c('h4', {
    attrs: {
      "id": "bottomFallback"
    }
  }, [_vm._v("Close this window to continue.")])] : _vm._e()], 2)], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }