import Vue from 'vue'

import crypto from 'crypto'
import { waitPromise } from '@happstv/shared/util/utils'

const EmailRequiredToast = () => import('@/toasts/EmailRequiredToast.vue')

const initState = () => ({
  fullscreenPopups: [],
  currentTransientToast: undefined,
  currentTransientToastHover: false,
  closeToastOnUnhover: false,
  queuedToasts: [],
  menuCloseFunctions: [],
})

export default {
  namespaced: true,

  state: initState(),

  getters: {
    currentToast(state, _, rootState) {
      const { currentTransientToast } = state
      if (currentTransientToast) return currentTransientToast

      const { currentRoute = {}, signedInUser = {} } = rootState

      const userExists = signedInUser?.creationDate
      const emailNotVerified = !signedInUser?.emailVerified
      const userViewingOwnProfile = currentRoute.params?.username === signedInUser?.username

      if((currentRoute.matched || []).some(({ name }) => name === 'userPage') && userExists && emailNotVerified && userViewingOwnProfile) {
        return {
          key: 'showEmailToast',
          component: EmailRequiredToast,
        }
      }

      return undefined
    },
  },

  mutations: {
    // payload is { component, params, maybe id }
    ADD_FULLSCREEN_POPUP(state, value) {
      state.fullscreenPopups.push(value)
    },
    REMOVE_FULLSCREEN_POPUP_AT_INDEX(state, index) {
      const [popup] = state.fullscreenPopups.splice(index, 1)
      if (popup) {
        if (popup.onClose) popup.onClose()
      }
    },
    REMOVE_FULLSCREEN_POPUP_BY_ID(state, id) {
      const index = state.fullscreenPopups.findIndex(popup => popup.id === id)
      if (index < 0) return

      const [popup] = state.fullscreenPopups.splice(index, 1)
      if (popup) {
        if (popup.onClose) popup.onClose()
      }
    },
    CLOSE_ALL_FULLSCREEN_POPUPS(state, { skipPageless } = {}) {
      state.fullscreenPopups = state.fullscreenPopups.filter(({ pageless }) => skipPageless && pageless)
    },

    ADD_QUEUED_TOAST(state, value) {
      state.queuedToasts.push(value)
    },
    SHOW_NEXT_TOAST(state) {
      const [toast] = state.queuedToasts.splice(0, 1)
      if (toast) toast.showTime = Date.now()
      state.currentTransientToast = toast
      state.currentTransientToastHover = false
      state.closeToastOnUnhover = false
    },
    SET_CURRENT_TOAST_HOVER(state, value) {
      state.currentTransientToastHover = value
    },
    SET_CLOSE_TOAST_ON_UNHOVER(state, value) {
      state.closeToastOnUnhover = value
    },

    ADD_MENU_CLOSE_FUNCTION(state, value) {
      state.menuCloseFunctions.push(value)
    },
    CLEAR_MENU_CLOSE_FUNCTIONS(state) {
      state.menuCloseFunctions = []
    },
  },

  actions: {
    async ADD_FULLSCREEN_POPUP({
      commit,
      dispatch,
      rootState,
    }, {
      component,
      params = {},
      popupType = 'dialog', // 'dialog', 'leftDrawer', 'rightDrawer'
      closeable = true,
      curtainColor,
      blurBackground,
      center,
      drawerWidth,
      id = crypto.randomBytes(8).toString('hex'),
      onClose,
      belowTopNav = false,
      pageless,
    }) {
      try {
        const popup = {
          // relevant to PopupLayer
          params,
          popupType,
          closeable,
          curtainColor,
          blurBackground,
          center,
          drawerWidth,

          // relevant to OverlayManager
          id,
          onClose,
          belowTopNav,
          pageless,
        }

        commit('ADD_FULLSCREEN_POPUP', popup)

        document.activeElement.blur()

        const resolvedComponent = (typeof component === 'function') ? (await component()).default : component
        Vue.set(popup, 'component', resolvedComponent)

        const analyticsProps = {}
        Object.keys(params).forEach((key) => {
          const value = params[key]
          if (typeof value !== 'function') analyticsProps[key] = value
        })

        dispatch('analytics/LOG_EVENT', {
          name: 'fullscreen_popup_shown',
          data: {
            // THIS BROUGHT US OVER THE AMPLITUDE PROP LIMIT; RETHINK THIS
            // popupProps: analyticsProps,
            popupName: resolvedComponent.name,
            popupPurpose: analyticsProps.popupPurpose,
            popupFullDescriptor: `${resolvedComponent.name}${analyticsProps.popupPurpose ? ` <- ${analyticsProps.popupPurpose}` : ''}`,
            popupType,
          },
        }, { root: true })

        return () => commit('REMOVE_FULLSCREEN_POPUP_BY_ID', id)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        rootState.router.reload()
        return () => {}
      }
    },
    REMOVE_FULLSCREEN_POPUP_AT_INDEX({ commit }, index) {
      commit('REMOVE_FULLSCREEN_POPUP_AT_INDEX', index)
    },

    async ADD_TOAST({
      commit,
      dispatch,
      state,
    }, {
      component,
      props = {},
      duration,
      zIndex,
      top,
    }) {
      try {
        const toast = {
          component,
          props,
          duration,
          zIndex,
          top,
        }

        if (typeof component === 'function') {
          toast.component = (await component()).default
        }
        commit('ADD_QUEUED_TOAST', toast)

        if (!state.currentTransientToast) dispatch('SHOW_NEXT_TOAST')
        // eslint-disable-next-line no-empty
      } catch (_) {}
    },

    async SHOW_NEXT_TOAST({
      state,
      commit,
      dispatch,
    }) {
      commit('SHOW_NEXT_TOAST')
      const { currentTransientToast } = state
      if (!currentTransientToast) return

      const { duration } = currentTransientToast
      if (!duration) return

      await waitPromise(duration)
      if (state.currentTransientToast !== currentTransientToast) return

      if (state.currentTransientToastHover) {
        commit('SET_CLOSE_TOAST_ON_UNHOVER', true)
        return
      }
      dispatch('SHOW_NEXT_TOAST')
    },
    SET_CURRENT_TOAST_HOVER({ commit, state, dispatch }, value) {
      commit('SET_CURRENT_TOAST_HOVER', value)
      if (!value && state.closeToastOnUnhover) dispatch('SHOW_NEXT_TOAST')
    },

    ADD_MENU_CLOSE_FUNCTION({ commit }, value) {
      commit('ADD_MENU_CLOSE_FUNCTION', value)
    },

    CLEAR_OVERLAYS({ state, commit }) {
      state.menuCloseFunctions.forEach(f => f())
      commit('CLEAR_MENU_CLOSE_FUNCTIONS')
      commit('SET_UNMUTED_AUTOPLAY_ALLOWED', true, { root: true })
    },
  },
}
