const {
  CloudWatchLogsClient,
  PutLogEventsCommand,
} = require('@aws-sdk/client-cloudwatch-logs')

const {
  format,
} = require('util')

const initState = () => ({
  logsInterval: undefined,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_LOGS_INTERVAL(state, value) {
      state.logsInterval = value
    },
  },

  actions: {
    START_AWS_CONSOLE_LOG_UPLOAD({
      state,
      commit,
    }, {
      logGroupName,
      logStreamName,
      credentials,
    }) {
      if (state.logsInterval) return

      const logsClient = new CloudWatchLogsClient({ credentials, region: 'us-west-2' })

      const logsQueue = []

      const logTypes = ['log', 'info', 'warn', 'error']

      logTypes.forEach((logType) => {
        // eslint-disable-next-line no-console
        const originalFunction = console[logType]
        // eslint-disable-next-line no-console
        console[logType] = (...args) => {
          originalFunction(...args)

          logsQueue.push({
            timestamp: Date.now(),
            logType,
            logArgs: args,
          })
        }
      })

      let sequenceToken

      const logsInterval = setInterval(async () => {
        const logEvents = logsQueue.splice(0).map((log) => {
          const {
            timestamp,
            logType,
            logArgs,
          } = log

          return {
            timestamp,
            message: `[[${logType.toUpperCase()}]] ${format(...logArgs)}`,
          }
        })

        if (!logEvents.length) return

        async function sendCommand(overrideSequenceToken) {
          if (overrideSequenceToken) sequenceToken = overrideSequenceToken

          const command = new PutLogEventsCommand({
            logGroupName,
            logStreamName,
            sequenceToken,
            logEvents,
          })

          try {
            const { nextSequenceToken } = (await logsClient.send(command)) || {}
            if (nextSequenceToken) sequenceToken = nextSequenceToken
          } catch (e) {
            if (e.expectedSequenceToken) sendCommand(e.expectedSequenceToken)
          }
        }

        await sendCommand()
      }, 2000)

      commit('SET_LOGS_INTERVAL', logsInterval)
    },
  },
}
