import PublicCreatorListPage from '@/pages/agent/public/PublicCreatorListPage.vue'

export default [
  {
    path: ':creatorListId',
    name: 'publicCreatorList',
    component: PublicCreatorListPage,
    meta: {
      noTopNav: true,
      authRequired: false,
      lightTheme: true,
    },
  },
]