
import performCloudFunction from '@/util/firebase/performCloudFunction'

const initState = () => ({
  streamData: [],
  profileUserId: undefined,
  loading: undefined,
  cursor: null,
  endOfFeed: false,
})

export default {
  namespaced: true,

  state: {
    ...initState(),
  },

  mutations: {
    REINIT(state) {
      Object.assign(state, initState())
    },

    SET_STREAM_DATA(state, payload) {
      state.streamData = payload
    },

    SET_LOADING(state, payload) {
      state.loading = payload
    },

    SET_CURSOR(state, payload) {
      state.cursor = payload
    },

    SET_END_OF_FEED(state, payload) {
      state.endOfFeed = payload
    },
  },

  actions: {
    async LOAD_STREAM_BATCH({ state, commit }, params) {
      const feedFilter = 'starlight'
      const feedSource = 'userProfile'
      const { profileUserId } = params
      const feedCursorToken = state.cursor || null
      
      if (state.loading || state.endOfFeed) return

      commit('SET_LOADING', true)
      
      const {
        success,
        error = 'Unknown error.',
        feedPosts,
        endOfFeed,
        newFeedCursorToken,
      } = await performCloudFunction('loadNewFeedPosts', {
        feedSource,
        feedFilter,
        profileUserId,
        feedCursorToken,
      })

      if (!success) {
        commit('SET_STREAM_DATA', { error })
        commit('SET_LOADING', false)
        return
      }

      feedPosts.forEach((feedPost) => {
        commit('posts/SET_POST', feedPost, { root: true })
      })
      
      commit('SET_STREAM_DATA', [...state.streamData, ...feedPosts])
      commit('SET_END_OF_FEED', endOfFeed)
      commit('SET_CURSOR', newFeedCursorToken)
      commit('SET_LOADING', false)
    }, 
  },
}
