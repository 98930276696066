import './firebase' // for side-effects
import Vue from 'vue'
import Vuex from 'vuex'
import ImageKit from 'imagekitio-vue'
import VueEllipseProgress from 'vue-ellipse-progress'
import VueApexCharts from 'vue-apexcharts'

import { isDev } from '@happstv/shared/util/utils'

import '@/assets/css/baseElements.css'
import '@/assets/css/globalClasses.css'
import '@/assets/css/animationKeyframes.css'
import '@/assets/font/Inter.css'
import '@/assets/font/Montserrat.css'

import clickOutside from '@/directives/clickOutside'
import observeDimensions from '@/directives/observeDimensions'

import LoadingSpinner from '@/components/universal/basic/LoadingSpinner.vue'
import PillButton from '@/components/universal/basic/PillButton.vue'
import MaybeRouterLink from '@/components/universal/MaybeRouterLink.vue'
import InlineMenu from '@/components/universal/InlineMenu.vue'
import InlineContext from '@/components/universal/InlineContext.vue'
import RelativeTimeString from '@/components/universal/RelativeTimeString.vue'
import MultilineText from '@/components/universal/basic/MultilineText.vue'

// add ability to close popups opened by this script
window.addEventListener('message', ({ data, source }) => {
  switch (data) {
    case 'isCloseMyselfAvailable':
      if (source.opener === window || source.parent === window) {
        source.postMessage('closeMyselfIsAvailable', '*')
        source.postMessage('closeMyselfIsAvailableIsStarlight', '*')
      }
      break
    case 'closeMyself':
      source.close()
      break
    default:
      break
  }
})

Vue.use(Vuex)

// Configure ImageKit.io
Vue.use(ImageKit, {
  urlEndpoint: isDev ? 'https://ik.imagekit.io/happs/dev/' : 'https://ik.imagekit.io/happs/prod/',
  publicKey: 'public_wDUGogf3D1xmG5R+BC323hKZPL0=',
})

Vue.use(VueEllipseProgress)

Vue.use(VueApexCharts)

Vue.config.productionTip = false

Vue.directive('click-outside', clickOutside)
Vue.directive('observe-dimensions', observeDimensions)

Vue.component('LoadingSpinner', LoadingSpinner)
Vue.component('PillButton', PillButton)
Vue.component('maybe-router-link', MaybeRouterLink)
Vue.component('InlineMenu', InlineMenu)
Vue.component('InlineContext', InlineContext)
Vue.component('RelativeTimeString', RelativeTimeString)
Vue.component('MultilineText', MultilineText)
Vue.component('apexchart', VueApexCharts)

module.exports = Vue
