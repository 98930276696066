var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "invitePage"
  }, [!_vm.activeCampaignList.length ? [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('LoadingSpinner')], 1)] : !_vm.calculationReturned ? [_c('div', {
    staticClass: "inviteContainer"
  }, [_c('h2', {
    staticClass: "inviteContainerHeader"
  }, [_vm._v("You've been invited to join Reach.")]), _c('p', {
    staticClass: "inviteContainerSubtitle"
  }, [_vm._v("Find out how much you could earn below.")]), _c('BrandCampaignUsernameInput', {
    staticClass: "usernameInput",
    attrs: {
      "error": _vm.usernameAttemptError,
      "submitFunction": _vm.submitUsername
    }
  }), _vm.usernameAttemptError ? _c('p', {
    staticClass: "usernameAttemptError"
  }, [_vm._v(_vm._s(_vm.usernameAttemptError))]) : _vm._e()], 1), _c('BrandCampaignVideoPreviewList', {
    staticClass: "activeCampaignList",
    attrs: {
      "campaignList": _vm.activeCampaignList,
      "listProcessing": _vm.processedActiveCampaignList
    }
  }), _c('div', {
    staticClass: "downloadAppSection"
  }, [_c('p', [_vm._v("Download Reach & Get Started Now")]), _c('AppBadges')], 1)] : [_c('BrandCampaignPotentialResult', {
    attrs: {
      "username": _vm.tiktokUsername,
      "maxPossibleEarnings": _vm.maxPossibleEarnings
    }
  }), _c('BrandCampaignVideoPreviewList', {
    attrs: {
      "campaignList": _vm.activeCampaignList,
      "listProcessing": _vm.processedActiveCampaignList
    }
  })], _c('transition', [_vm.calculationStarted && (!_vm.calculationReturned || !_vm.loadingAnimationCompleted) ? _c('ProfileLoadingScreen', {
    staticClass: "fullscreen",
    attrs: {
      "stepList": _vm.splashStepList,
      "endFunction": function endFunction() {
        return _vm.loadingAnimationCompleted = true;
      }
    }
  }) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }