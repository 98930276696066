
const initState = () => ({
  campaignManagerUserIdFilter: undefined,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    REINIT(state) {
      Object.assign(state, initState())
    },
    SET_CAMPAIGN_MANAGER_USER_ID_FILTER(state, newValue) {
      state.campaignManagerUserIdFilter = newValue
    },
  },
}
