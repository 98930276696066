import { USER_PAGE_SETTINGS_DOC_PATH } from '@happstv/shared/util/firebase/firestorePaths'
import {
  watchDoc,
  // setDoc,
} from '@happstv/shared/util/firebase/firestoreUtils'

const initState = () => ({
  userPageLinks: [],
  userPageSettingsUnsubscribe: undefined,
  userPageSettingsLoading: true,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_USER_PAGE_LINKS(state, payload) {
      state.userPageLinks = payload
    },
    SET_USER_PAGE_SETTINGS_LOADING(state, payload) {
      state.userPageSettingsLoading = payload
    },
    SET_USER_PAGE_SETTINGS_UNSUBSCRIBE(state, payload) {
      state.userPageSettingsUnsubscribe = payload
    },
    REINIT(state) {
      Object.assign(state, initState())
    },
  },

  actions: {
    REINIT({ commit, state }) {
      if (state.userPageSettingsUnsubscribe) state.userPageSettingsUnsubscribe()
      commit('REINIT')
    },
    WATCH_USER_PAGE_SETTINGS({ commit }, { userId }) {
      if (!userId) return

      const userPageSettingsUnsubscribe = watchDoc(USER_PAGE_SETTINGS_DOC_PATH(userId), (userPageLinksSnap) => {
        const { userPageLinks = [] } = userPageLinksSnap.data() || {}
        commit('SET_USER_PAGE_LINKS', userPageLinks)
        commit('SET_USER_PAGE_SETTINGS_LOADING', false)
      })
      commit('SET_USER_PAGE_SETTINGS_UNSUBSCRIBE', userPageSettingsUnsubscribe)
    },
  },
}
