import Vue from 'vue'

import {
  watchDoc,
} from '@happstv/shared/util/firebase/firestoreUtils'

import {
  USER_FOLLOWER_DOC_PATH,
} from '@happstv/shared/util/firebase/firestorePaths'

import performCloudFunction from '@/util/firebase/performCloudFunction'

const SimpleDialog = () => import('@/dialogs/universal/SimpleDialog.vue')

const personalState = () => ({
  userFollowersByUserId: {},
  userFollowingByUserId: {},
  userFollowersUnsubscribesByUserId: {},
  userFollowingUnsubscribesByUserId: {},

  latestSuggestedUserDoc: undefined,
  suggestedUsersToFollow: [],
  endOfSuggestedUsers: false,
})

const uiState = () => ({
  userFollowersWatchCountsByUserId: {},
  userFollowingWatchCountsByUserId: {},
})

export default {
  namespaced: true,

  state: {
    ...personalState(),
    ...uiState(),
  },

  mutations: {
    REINIT(state) {
      Object.values(state.userFollowersUnsubscribesByUserId).filter(u => u).forEach(u => u())
      Object.values(state.userFollowingUnsubscribesByUserId).filter(u => u).forEach(u => u())

      Object.assign(state, personalState())
    },

    SET_FOLLOWER_DATA(state, { followerUserId, data }) {
      Vue.set(state.userFollowersByUserId, followerUserId, data)
    },
    SET_FOLLOWING_DATA(state, { followingUserId, data }) {
      Vue.set(state.userFollowingByUserId, followingUserId, data)
    },
    SET_FOLLOWER_UNSUBSCRIBE(state, { followerUserId, unsubscribe }) {
      if (state.userFollowersUnsubscribesByUserId[followerUserId]) state.userFollowersUnsubscribesByUserId[followerUserId]()
      Vue.set(state.userFollowersUnsubscribesByUserId, followerUserId, unsubscribe)
    },
    SET_FOLLOWING_UNSUBSCRIBE(state, { followingUserId, unsubscribe }) {
      if (state.userFollowingUnsubscribesByUserId[followingUserId]) state.userFollowingUnsubscribesByUserId[followingUserId]()
      Vue.set(state.userFollowingUnsubscribesByUserId, followingUserId, unsubscribe)
    },
    INCREMENT_FOLLOWER_WATCH_COUNT(state, { followerUserId, increment = 1 }) {
      const newValue = (state.userFollowersWatchCountsByUserId[followerUserId] || 0) + increment
      Vue.set(state.userFollowersWatchCountsByUserId, followerUserId, newValue)
    },
    INCREMENT_FOLLOWING_WATCH_COUNT(state, { followingUserId, increment = 1 }) {
      const newValue = (state.userFollowingWatchCountsByUserId[followingUserId] || 0) + increment
      Vue.set(state.userFollowingWatchCountsByUserId, followingUserId, newValue)
    },

    SET_LATEST_SUGGESTED_USER_DOC(state, value) {
      state.latestSuggestedUserDoc = value
    },
    SET_SUGGESTED_USERS_TO_FOLLOW(state, value) {
      state.suggestedUsersToFollow = value.filter(({ id }, i, a) => i === a.findIndex(item => item.id === id))
    },
    SET_END_OF_SUGGESTED_USERS(state, value) {
      state.endOfSuggestedUsers = value
    },
  },

  actions: {
    REINIT({ commit, dispatch, state }) {
      commit('REINIT')

      const { userFollowersWatchCountsByUserId, userFollowingWatchCountsByUserId } = state
      Object.keys(userFollowersWatchCountsByUserId).filter(key => userFollowersWatchCountsByUserId[key] > 0).forEach((userId) => {
        dispatch('START_FOLLOWER_WATCHER_IF_NECESSARY', userId)
      })
      Object.keys(userFollowingWatchCountsByUserId).filter(key => userFollowingWatchCountsByUserId[key] > 0).forEach((userId) => {
        dispatch('START_FOLLOWING_WATCHER_IF_NECESSARY', userId)
      })
    },

    START_FOLLOWER_WATCHER_IF_NECESSARY({ commit, state, rootState }, followerUserId) {
      const { signedInUserId } = rootState
      if (!signedInUserId) {
        commit('SET_FOLLOWER_DATA', { followerUserId, data: {} })
        return
      }

      if (state.userFollowersUnsubscribesByUserId[followerUserId]) return

      const unsubscribe = watchDoc(USER_FOLLOWER_DOC_PATH(signedInUserId, followerUserId), (followDoc) => {
        const data = followDoc.data() || {}
        commit('SET_FOLLOWER_DATA', { followerUserId, data })
      })
      commit('SET_FOLLOWER_UNSUBSCRIBE', { followerUserId, unsubscribe })
    },
    async START_FOLLOWING_WATCHER_IF_NECESSARY({ commit, state, rootState }, followingUserId) {
      await rootState.waitingAuthPromise
      const { signedInUserId } = rootState
      if (!signedInUserId) {
        commit('SET_FOLLOWING_DATA', { followingUserId, data: {} })
        return
      }

      if (state.userFollowingUnsubscribesByUserId[followingUserId]) return

      const unsubscribe = watchDoc(USER_FOLLOWER_DOC_PATH(followingUserId, signedInUserId), (followDoc) => {
        const data = followDoc.data() || {}
        commit('SET_FOLLOWING_DATA', { followingUserId, data })
      })
      commit('SET_FOLLOWING_UNSUBSCRIBE', { followingUserId, unsubscribe })
    },
    WATCH_FOLLOWER({ dispatch, commit }, followerUserId) {
      commit('INCREMENT_FOLLOWER_WATCH_COUNT', { followerUserId })
      dispatch('START_FOLLOWER_WATCHER_IF_NECESSARY', followerUserId)
    },
    WATCH_FOLLOWING({ dispatch, commit }, followingUserId) {
      commit('INCREMENT_FOLLOWING_WATCH_COUNT', { followingUserId })
      dispatch('START_FOLLOWING_WATCHER_IF_NECESSARY', followingUserId)
    },
    UNWATCH_FOLLOWER({ commit, state }, followerUserId) {
      commit('INCREMENT_FOLLOWER_WATCH_COUNT', { followerUserId, increment: -1 })
      if (state.userFollowersWatchCountsByUserId[followerUserId] <= 0) commit('SET_FOLLOWER_UNSUBSCRIBE', { followerUserId })
    },
    UNWATCH_FOLLOWING({ commit, state }, followingUserId) {
      commit('INCREMENT_FOLLOWING_WATCH_COUNT', { followingUserId, increment: -1 })
      if (state.userFollowingWatchCountsByUserId[followingUserId] <= 0) commit('SET_FOLLOWING_UNSUBSCRIBE', { followingUserId })
    },

    async FOLLOW_USER({
      commit,
      state,
      dispatch,
    }, { followingUserId, follow, analyticsData }) {
      dispatch('analytics/LOG_EVENT', {
        name: follow ? 'follow_user_clicked' : 'unfollow_user_clicked',
      }, { root: true })

      const { success, error } = await performCloudFunction('followUser', { followingUserId, follow, analyticsData, smsFollow: true })
      if (!success) {
        dispatch('overlays/ADD_FULLSCREEN_POPUP', {
          component: SimpleDialog,
          params: {
            popupPurpose: 'communityUserFollows.followUser.failure',
            title: 'Failed to Follow User',
            text: error,
            borderColor: 'var(--happsRed)',
          },
        }, { root: true })
        return
      }

      commit('SET_FOLLOWING_DATA', {
        followingUserId,
        data: {
          ...(state.userFollowingByUserId[followingUserId] || {}),
          follow,
        },
      })

      dispatch('analytics/LOG_EVENT', {
        name: follow ? 'web_followed_user' : 'web_unfollowed_user',
        data: {
          user_id: followingUserId,
          followRecipientUserId: followingUserId,
        },
      }, { root: true })

      if (follow) {
        dispatch('analytics/LOG_PIXEL', { name: 'AddToWishlist' }, { root: true })
        dispatch('analytics/LOG_GTAG', { name: 'subscribe_to_user' }, { root: true })
      }
    },
  },
}
