//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { TIKTOK_NATIVE } from '@happstv/shared/util/multicastingConstants'
import { makeReachDynamicLink } from '@happstv/shared/util/makeReachDynamicLink'
import { makeReachUrl } from '@happstv/shared/util/makeReachUrl'
import { numberMagnitudeString, date } from '@happstv/shared/util/utils'
import { getCol } from '@happstv/shared/util/firebase/firestoreUtils'
import { BRAND_CAMPAIGNS_COL_PATH } from '@happstv/shared/util/firebase/firestorePaths'
import performCloudFunction from '@/util/firebase/performCloudFunction'
import BrandCampaignUsernameInput from '@/components/brands/BrandCampaignUsernameInput.vue'
import BrandCampaignVideoPreviewList from '@/components/brands/BrandCampaignVideoPreviewList.vue'
import BrandCampaignPotentialResult from '@/components/brands/BrandCampaignPotentialResult.vue'
import ProfileLoadingScreen from '@/components/brands/ProfileLoadingScreen.vue'
import AppBadges from '@/components/app/AppBadges.vue'

export default {
  name: 'BaseInvitePage',
  components: {
    BrandCampaignUsernameInput,
    BrandCampaignVideoPreviewList,
    ProfileLoadingScreen,
    AppBadges,
    BrandCampaignPotentialResult,
  },
  computed: {
    universalLink() { return makeReachUrl(this.brandCampaignId, { isCampaign: true }) },
  },
  data() {
    return {
      tiktokUsername: undefined,
      activeCampaignList: [],
      calculationStarted: false,
      calculationReturned: false,
      loadingAnimationCompleted: false,
      usernameAttemptError: undefined,
      makeReachDynamicLink,
      makeReachUrl,
      maxPossibleEarnings: 0,
      splashStepList: [
        'Importing View Count',
        'Analyzing Engagement',
        'Building Offer',
        'Preparing Presentation',
      ],
      numberMagnitudeString,
    }
  },
  methods: {
    async submitUsername(username) {
      this.tiktokUsername = username
      if (username) {
        this.$store.dispatch('analytics/LOG_EVENT', {
          name: 'tiktok_username_submitted',
          data: {
            username,
          },
        })

        this.calculationStarted = true
        const { success, maxPossibleEarnings, activeCampaignList } = await performCloudFunction('calculateOffPlatformUserPotential', { platform: TIKTOK_NATIVE, platformUserData: { username } })
       
        if (success) {
          this.calculationReturned = true
          this.maxPossibleEarnings = maxPossibleEarnings
          this.activeCampaignList = activeCampaignList
        } else {
          this.calculationStarted = false
          this.loadingAnimationCompleted = false
          this.usernameAttemptError = 'An error occurred! Please double check your username and try again!'
        }
      }
    },
    processedActiveCampaignList(campaignArray) {
      let processedList = [...campaignArray]
        .filter(campaign => campaign.profileVideoList.some(({ url }) => url))
        .sort((a,b) => date(b.creationDate) - date(a.creationDate))
      return processedList
    },
    async initializeActiveCampaignList() {
      const currentActiveCampaigns = await getCol(BRAND_CAMPAIGNS_COL_PATH, q => q.where('userSwipesEnabled', '==', true))
      const currentActiveCampaignDocList = currentActiveCampaigns.docs || []
      const activeCampaignList = currentActiveCampaignDocList.map(campaignDoc => ({ id: campaignDoc.id, ...(campaignDoc.data() || {}) }))
      this.activeCampaignList = activeCampaignList
    },
  },
  mounted() {
    this.initializeActiveCampaignList()
    const { tiktok } = this.$route.query
    if (tiktok) {
      this.submitUsername(tiktok)
    }
  },
}
