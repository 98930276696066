
import { formatNumber, numberMagnitudeString } from '@happstv/shared/util/utils'

import {
  getRelevantStatsList,
  getSubmissionTypeFromRelationship,
  getExternalPlatformBySubmittableType,
  getBrandCampaignObjectivesProgress,
} from '@happstv/shared/util/brands/brandCampaignUtils'

import {
  STATS_PROJECTION_DAY_COUNTS,
} from '@happstv/shared/util/brands/brandCampaignPerformanceUtils'

const UserCampaignApplicationsDialog = () => import('@/dialogs/console/brands/UserCampaignApplicationsDialog.vue')


export const SUBMISSION_STAT_LIST = [
  {
    key: 'stats.comparableViewCount',
    label: '"Comparable"<br>Views',
    tooltip: 'The submission\'s views after "at least 14 days." If the submission is less than 14 days old, this is a projection. Otherwise, it\'s the actual current views.',
    getter({ stats = {} }) {
      return stats.comparableViewCount ? `${numberMagnitudeString(stats.comparableViewCount)}%` : '-'
    },
    getColor() {
      return '#0085FF'
    },
  },
  {
    key: 'comparableViewOverperformance',
    label: '"Comparable"<br>Overperformance',
    tooltip: 'The submission\'s "Comparable" views compared to the creator\'s median views.',
    getter({ comparableViewOverperformance }) {
      return comparableViewOverperformance ? `${formatNumber(comparableViewOverperformance * 100, 1)}%` : '-'
    },
    getColor({ comparableViewOverperformance }) {
      return comparableViewOverperformance > 1 ? '#01FD3F' : '#FF1272'
    },
  },
  {
    key: 'engagementOverperformance',
    label: 'Engagement<br>Overperformance',
    tooltip: 'The submission\'s engagement rate compared to the creator\'s median engagement rate.',
    getter({ engagementOverperformance }) {
      return engagementOverperformance ? `${formatNumber(engagementOverperformance * 100, 1)}%` : '-'
    },
    getColor({ engagementOverperformance }) {
      return engagementOverperformance > 1 ? '#01FD3F' : '#FF1272'
    },
  },
  {
    key: 'boostabilityScore',
    label: 'Boostability<br>Score',
    tooltip: 'A predictor of how likely the post is to be successful when boosted with Spark Ads. Calculated by multiplying the engagement rate by an "engagement confidence score" based on viewership. Anything over <code>1.0</code> is considered "good".<br><br>Formula: <code>engagementRate * [0 <= log10(views) <= 4] / (0.15 * 3)</code>',
    getter({ boostabilityScore }) {
      return boostabilityScore ? `${formatNumber(boostabilityScore, 1)}` : '-'
    },
    getColor({ boostabilityScore }) {
      return boostabilityScore > 1 ? '#01FD3F' : '#FF1272'
    },
  },
]

export const USER_STAT_LIST = [
  {
    key: 'country',
    label: 'Primary Country<br>(by Phone #)',
    showByDefault: true,
    getter({ userRelationshipData = {} }) {
      const { phoneCountry } = userRelationshipData
      if (!phoneCountry) return undefined

      const emoji = phoneCountry.length === 2 ? String.fromCodePoint(...phoneCountry.toUpperCase().split('').map(char =>  127397 + char.charCodeAt())) : ''
      return `${emoji} ${phoneCountry}`
    },
  },
  {
    key: 'device-country',
    label: 'Latest Country<br>(by IP address)',
    showByDefault: true,
    getter({ userRelationshipData = {} }) {
      const { latestDeviceCountry } = userRelationshipData
      if (!latestDeviceCountry) return undefined

      const emoji = latestDeviceCountry.length === 2 ? String.fromCodePoint(...latestDeviceCountry.toUpperCase().split('').map(char =>  127397 + char.charCodeAt())) : ''
      return `${emoji} ${latestDeviceCountry}`
    },
    getColor({ userRelationshipData = {} }) {
      const { latestDeviceCountry, phoneCountry } = userRelationshipData
      if (latestDeviceCountry !== phoneCountry) return '#FF1272'
      return undefined
    },
  },
  {
    key: 'applications',
    label: 'Applications',
    showByDefault: true,
    getter({ userRelationshipData = {} }) {
      const {
        brandCampaignRelationshipStats = {},
      } = userRelationshipData

      const {
        applicationCount = 0,
      } = brandCampaignRelationshipStats
      if (!applicationCount) return undefined

      return formatNumber(applicationCount)
    },
    onClick({ userRelationshipData = {}, userId, brandCampaignId }) {
      const { username } = userRelationshipData
      this.$store.dispatch('overlays/ADD_FULLSCREEN_POPUP', {
        component: UserCampaignApplicationsDialog,
        params: {
          title: `Campaign Applications ${username ? `for @${username}` : ''}`,
          userId,
          currentSelectedCampaignId: brandCampaignId,
        },
      })
    },
  },
  {
    key: 'conversion',
    label: 'Approved Applications<br>➔ Submits ➔ Approved',
    showByDefault: true,
    getter({ userRelationshipData = {} }) {
      const {
        brandCampaignRelationshipStats = {},
      } = userRelationshipData

      const {
        matchCount = 0,
        submissionCount = 0,
        submissionApprovalCount = 0,
      } = brandCampaignRelationshipStats
      if (!matchCount) return undefined

      return `${formatNumber(matchCount)} ➔ ${formatNumber(submissionCount)} ➔ ${formatNumber(submissionApprovalCount)} (${formatNumber(submissionApprovalCount / matchCount * 100, 0)}%)`
    },
  },
  {
    key: 'otherSubmissions',
    label: 'Submissions to Other<br>Campaigns (same Brand)',
    showByDefault: true,
    getter(relationshipData = {}) {
      const { brandSubmissionCount = 0 } = relationshipData
      return brandSubmissionCount
    },
  },
  {
    key: 'followers',
    label: 'Approx<br>Followers',
    showByDefault: true,
    getter(relationshipData) {
      const submissionType = getSubmissionTypeFromRelationship(relationshipData)
      const externalPlatform = getExternalPlatformBySubmittableType(submissionType)
      const {
        userRelationshipData = {},
      } = relationshipData || {}

      const {
        log10MetricsByPlatform = {},
      } = userRelationshipData

      const {
        log10FollowerCount = null,
      } = log10MetricsByPlatform[externalPlatform] || {}

      if (log10FollowerCount === null) return undefined

      return numberMagnitudeString(Math.pow(10, log10FollowerCount))
    },
  },
  {
    key: 'medianViews',
    label: 'Median<br>Views',
    showByDefault: true,
    getter(relationshipData) {
      const submissionType = getSubmissionTypeFromRelationship(relationshipData)
      const externalPlatform = getExternalPlatformBySubmittableType(submissionType)
      const {
        userRelationshipData = {},
      } = relationshipData || {}

      const {
        log10MetricsByPlatform = {},
      } = userRelationshipData

      const {
        log10MedianViewCount = null,
      } = log10MetricsByPlatform[externalPlatform] || {}

      if (log10MedianViewCount === null) return undefined

      return numberMagnitudeString(Math.pow(10, log10MedianViewCount))
    },
  },
  {
    key: 'dashboardScore',
    label: 'Reach<br>Score',
    tooltip: 'A measure of viewership boosted by higher engagement quality. Just like Views, it is <i>dependent</i> of creator size.<br><br>Formula: <code>views + (10 * likes) + (400 * shares)</code>',
    showByDefault: true,
    getter(relationshipData) {
      const submissionType = getSubmissionTypeFromRelationship(relationshipData)
      const externalPlatform = getExternalPlatformBySubmittableType(submissionType)
      const {
        userRelationshipData = {},
      } = relationshipData || {}

      const {
        log10MetricsByPlatform = {},
      } = userRelationshipData

      const {
        log10MedianDashboardScore = null,
      } = log10MetricsByPlatform[externalPlatform] || {}

      if (log10MedianDashboardScore === null) return undefined

      return numberMagnitudeString(Math.pow(10, log10MedianDashboardScore))
    },
  },
  {
    key: 'engagementRate',
    label: 'Engagement<br>Rate',
    showByDefault: true,
    getter(relationshipData) {
      const submissionType = getSubmissionTypeFromRelationship(relationshipData)
      const externalPlatform = getExternalPlatformBySubmittableType(submissionType)
      const {
        userRelationshipData = {},
      } = relationshipData || {}

      const {
        log10MetricsByPlatform = {},
      } = userRelationshipData

      const {
        log10MedianEngagementRate = null,
      } = log10MetricsByPlatform[externalPlatform] || {}

      if (log10MedianEngagementRate === null) return undefined

      return `${formatNumber(Math.pow(10, log10MedianEngagementRate) * 100, 1)}%`
    },
  },
  {
    key: 'engagementQuality',
    label: 'Engagement<br>Quality',
    tooltip: 'A measure of engagement that favors shares over views, and ignores comments. Just like Engagement Rate, it is <i>independent</i> of creator size.<br><br>Formula: <code>(likes + (40 * shares)) / views</code>',
    showByDefault: true,
    getter(relationshipData) {
      const submissionType = getSubmissionTypeFromRelationship(relationshipData)
      const externalPlatform = getExternalPlatformBySubmittableType(submissionType)
      const {
        userRelationshipData = {},
      } = relationshipData || {}

      const {
        log10MetricsByPlatform = {},
      } = userRelationshipData

      const {
        log10MedianEngagementQuality = null,
      } = log10MetricsByPlatform[externalPlatform] || {}

      if (log10MedianEngagementQuality === null) return undefined

      return `${formatNumber(Math.pow(10, log10MedianEngagementQuality) * 100, 1)}%`
    },
  },
  {
    key: 'starRating',
    label: 'Avg Submission<br>Star Rating',
    showByDefault: true,
    getter({ userRelationshipData = {} }) {
      const {
        submissionQualityScores = {},
      } = userRelationshipData

      const {
        average = 0,
      } = submissionQualityScores

      if (!average) return undefined

      return formatNumber(average * 5, 1)
    },
    getColor({ userRelationshipData = {} }) {
      const {
        submissionQualityScores = {},
      } = userRelationshipData

      const {
        average = 0,
      } = submissionQualityScores

      if (average <= 0.4) return '#FF1272'

      return undefined
    },
  },
  {
    key: 'totalEarnings',
    label: 'All-Time<br>Earnings',
    showByDefault: true,
    getter(_, bankUserAccount) {
      if (!bankUserAccount) return '...'

      const { aggregatedBookkeepingBalances = {} } = bankUserAccount
      const { costs = {} } = aggregatedBookkeepingBalances
      const { net } = costs
      if (!net) return undefined

      return `$${formatNumber(net, 2)}`
    },
  },
  {
    key: 'walletBalance',
    label: 'Unpaid<br>Earnings',
    showByDefault: true,
    getter(_, bankUserAccount) {
      if (!bankUserAccount) return '...'

      const { aggregatedBookkeepingBalances = {} } = bankUserAccount
      const { liabilities = {} } = aggregatedBookkeepingBalances
      const { net } = liabilities
      if (!net) return undefined

      return `$${formatNumber(net, 2)}`
    },
  },
]

export const getProjectedDaysRemaining = (brandCampaign) => {
  const { internalTargetViewCount } = brandCampaign || {}
  const currentStatsList = getRelevantStatsList(brandCampaign)
  const currentViewCount = currentStatsList.reduce((prevResult, stats) => prevResult + (stats.viewCount || 0), 0)
  if (currentViewCount >= internalTargetViewCount) return 0

  const campaignWideProjections = getRelevantStatsList(brandCampaign, undefined, true)
 
  const combinedProjectionsByDay = {}
  STATS_PROJECTION_DAY_COUNTS.forEach((projectionDayCount) => {
    const projectedViewCount = campaignWideProjections
      .map(({ dailyProjections = {} }) => dailyProjections[projectionDayCount] || {})
      .reduce((prevResult, stats) => prevResult + (stats.viewCount || 0), 0)
    combinedProjectionsByDay[projectionDayCount] = { viewCount: projectedViewCount }
  })

  if (internalTargetViewCount && Object.values(combinedProjectionsByDay).length) {
    let upperIndex = 0
    // Look for a place where we hit the target view count
    const upperBound = Object.values(combinedProjectionsByDay).find(({ viewCount }, index) => {
      upperIndex = index
      return viewCount >= internalTargetViewCount
    })
    // This catches the case that our 14 day forecast does not hit the target view count
    if (!upperBound) return 'Too Long to Tell'

    const lowerBound = Object.values(combinedProjectionsByDay)[upperIndex - 1] || {}
    // Lower bound should default to current view count so that we can interpolate between 0 and 1 days properly if we are expecting to hit it within a day
    const [lowerViews, upperViews] = [lowerBound.viewCount || currentViewCount, upperBound.viewCount]

    // Interpolate between both values
    const daysRemaining = ((internalTargetViewCount - lowerViews) / (upperViews - lowerViews)) + upperIndex
    return formatNumber(daysRemaining, 1)
  }
  return undefined
}

export const getProjectedStatsForCampaign = (brandCampaign, projectionDayCount) => {
  return getRelevantStatsList(brandCampaign, undefined, false, true)
    .map(({ dailyProjections = {} }) => dailyProjections[projectionDayCount] || {})
}

export const CUSTOM_CURRENCY_LIST = [
  { key: undefined, label: 'US Dollars', prefix: '$', suffix: 'USD', digits: 2 },
  { key: 'gbp', label: 'Pound Sterling', prefix: '£', suffix: 'GBP', digits: 2 },
  { key: 'eur', label: 'Euros', prefix: '€', suffix: 'EUR', digits: 2 },
]

export const getBrandCampaignMissingFieldList = (brandCampaign = {}) => {
  const value = []
  if (!brandCampaign.fullName) value.push('Name')
  if (!brandCampaign.subTitle) value.push('Byline')
  if (!brandCampaign.profilePhotoPath) value.push('Photo')
  if (!brandCampaign.description) value.push('Brief')
  if (!brandCampaign.campaignType) value.push('Type')
  if (brandCampaign.campaignType === 'custom' && !brandCampaign.campaignTypeCustomLabel) {
    value.push('Custom Type Label')
  }
  if (!brandCampaign.economicType) value.push('Bookkeeping Category')

  if (!brandCampaign.submissionWindowDuration) value.push('Submission Window Duration')

  if (!(brandCampaign.profileVideoList || []).length) value.push('Media Assets')

  if (!brandCampaign.offer) {
    value.push('Offer')
  }

  if (!brandCampaign.campaignTerms) value.push('Terms & Conditions')

  if (!(brandCampaign.needsBrandApprovalDmActionList || []).length) value.push('Messaging on Pending Approval')

  if (!(brandCampaign.isMatchedDmActionList || []).length) value.push('Messaging on Application Approval')
  if (!(brandCampaign.isMatchedWithDraftDmActionList || []).length) value.push('Messaging on Application Approval with Draft')
  if (!(brandCampaign.submissionApprovedServerActionList || []).length) value.push('Messaging on Submission Approval')
  if (!(brandCampaign.submissionCertifiedWithEarningsServerActionList || []).length) value.push('Messaging on Certification - Earned Money')
  if (!(brandCampaign.submissionCertifiedWithoutEarningsServerActionList || []).length) value.push('Messaging on Certification - No Earnings')

  if (!(brandCampaign.staticReplyList || []).length) value.push('Instant Chat Reply Buttons')
  if ((brandCampaign.staticReplyList || []).some(({ serverResponseActionList = [] }) => !serverResponseActionList.length)) value.push('Instant Chat Missing Response')

  if (!brandCampaign.submissionRequirements) value.push('Submission Requirements')

  if (!brandCampaign.submissionType) value.push('Submission type')
  if (!brandCampaign.draftType) value.push('Draft type')
  if (brandCampaign.campaignType === 'song') {
    if (!brandCampaign.soundId) value.push('Required Sound')
    if (!brandCampaign.soundId) value.push('Required Sound Name')
  }

  if (!brandCampaign.internalTargetLaunchDate) value.push('Launch Date')
  if (!brandCampaign.internalTargetDurationDays) value.push('Target Duration')
  if (brandCampaign.internalTargetRevenue === undefined) value.push('Revenue (from Brand)')
  if (brandCampaign.internalTargetBudget === undefined) value.push('Target Spend')
  if (!brandCampaign.internalTargetCreateCount) value.push('Target Creates')
  if (!brandCampaign.internalTargetViewCount) value.push('Target Views')
  if (!brandCampaign.internalTargetNotes) value.push('Objective Notes')

  if (!(brandCampaign.discoverTargetList || []).length) value.push('Target Creator Filter')

  if (!brandCampaign.internalCampaignManagerUserId) value.push('Campaign Manager')

  if (!Object.values(brandCampaign.applicationFlowMap || {}).some(to => to)) value.push('Application Flow')

  return value
}

export const getBrandCampaignSentimentStatusColor = (latestIncomingEmailSentiment) => {
  if (latestIncomingEmailSentiment <= 0.2) {
    return '#FF1272'
  } else if (latestIncomingEmailSentiment <= 0.4) {
    return '#FF6A14'
  } else if (latestIncomingEmailSentiment <= 0.6) {
    return '#f0c200'
  } else if (latestIncomingEmailSentiment <= 0.8) {
    return '#B5D110'
  } else {
    return '#01FD3F'
  }
}


/**
 * 
 * @returns
 *  - undefined if not included in this KPI's tracking
 *  - true / false otherwise
 * 
 */
export function getBrandCampaignCommsKpiStatus(brandCampaign) {
  const {
    communications: {
      daysSinceLatest,
      latestIsAfterCampaignCompletion,
    } = {},
    duration: {
      progress: durationProgress,
    } = {},
  } = getBrandCampaignObjectivesProgress(brandCampaign)

  if (brandCampaign.earningsEnabled) {
    if (daysSinceLatest !== undefined && !latestIsAfterCampaignCompletion && durationProgress > 0) {
      return daysSinceLatest <= 2
    }
  }

  return undefined
}

export function getBrandCampaignOnTimeKpiStatus(brandCampaign) {
  const {
    earningsEnabled,
  } = brandCampaign

  const {
    internalTargetCreateCount: {
      target,
      value: createCount,
      leadOfDurationDelayedProgress,
    } = {},
    duration: {
      delayedProgress: durationDelayedProgress,
    } = {},
  } = getBrandCampaignObjectivesProgress(brandCampaign)

  if (earningsEnabled && (durationDelayedProgress > 0 || createCount > 0)) {
    if (target !== undefined && leadOfDurationDelayedProgress !== undefined) {
      if (leadOfDurationDelayedProgress >= 0) {
        return true
      }
      return false
    }
  }

  return undefined
}

export function getBrandCampaignSentimentKpiStatus(brandCampaign) {
  const {
    earningsEnabled,
    latestBrandIncomingCommunicationSentiment,
  } = brandCampaign

  const {
    internalTargetViewCount: {
      value: viewCount,
    } = {},
    duration: {
      delayedProgress: durationDelayedProgress,
    } = {},
  } = getBrandCampaignObjectivesProgress(brandCampaign)

  if (earningsEnabled && (durationDelayedProgress > 0 || viewCount > 0)) {
    if (latestBrandIncomingCommunicationSentiment !== undefined) {
      if (latestBrandIncomingCommunicationSentiment >= 0.8) {
        return true
      }
      return false
    }
  }

  return undefined
}
