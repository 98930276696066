
import {
  EXTERNAL_PLATFORM_ACCOUNTS_GROUP_NAME,
  USER_EXTERNAL_PLATFORM_ACCOUNT_DOC_PATH,
} from '@happstv/shared/util/firebase/firestorePaths'

import {
  setDoc,
  watchColGroup,
} from '@happstv/shared/util/firebase/firestoreUtils'

import performCloudFunction from '@/util/firebase/performCloudFunction'


const initState = () => ({
  accountListByPlatform: {},
  accountsUnsubscribe: undefined,
})

const initPersistentState = () => ({
  externalPlatformsInfo: undefined,
  externalPlatformsOrderList: undefined,
})

export default {
  namespaced: true,

  state: {
    ...initState(),
    ...initPersistentState(),
  },

  mutations: {
    REINIT(state) {
      if (state.accountsUnsubscribe) state.accountsUnsubscribe()
      Object.assign(state, initState())
    },

    SET_EXTERNAL_PLATFORMS_INFO(state, externalPlatformsInfo) {
      state.externalPlatformsInfo = externalPlatformsInfo
    },
    SET_EXTERNAL_PLATFORMS_ORDER_LIST(state, externalPlatformsOrderList) {
      state.externalPlatformsOrderList = externalPlatformsOrderList
    },
    SET_ACCOUNT_LIST_BY_PLATFORM(state, accountListByPlatform) {
      state.accountListByPlatform = accountListByPlatform
    },
    SET_ACCOUNTS_UNSUBSCRIBE(state, unsubscribe) {
      if (state.accountsUnsubscribe) state.accountsUnsubscribe()
      state.accountsUnsubscribe = unsubscribe
    },
  },

  actions: {
    WATCH_CONNECTED_ACCOUNTS({ commit, dispatch, rootState }) {
      commit('REINIT')

      const { signedInUserId } = rootState
      if (!signedInUserId) return

      dispatch('GET_EXTERNAL_PLATFORMS_INFO')

      const unsubscribe = watchColGroup(EXTERNAL_PLATFORM_ACCOUNTS_GROUP_NAME, q => q.where('userId', '==', signedInUserId), (accountsSnap) => {
        const accountListByPlatform = {}

        const accountDocs = accountsSnap.docs || []
        accountDocs.forEach((accountDoc) => {
          const account = accountDoc.data() || {}
          const { platform } = account
          if (!platform) return

          if (!accountListByPlatform[platform]) accountListByPlatform[platform] = []
          accountListByPlatform[platform].push(account)
        })

        commit('SET_ACCOUNT_LIST_BY_PLATFORM', accountListByPlatform)
      })

      commit('SET_ACCOUNTS_UNSUBSCRIBE', unsubscribe)
    },

    WATCH_PROPRIETARY_ACCOUNTS({ commit }, { platform }) {
      if (!platform) return

      commit('REINIT')

      const unsubscribe = watchColGroup(EXTERNAL_PLATFORM_ACCOUNTS_GROUP_NAME, q => q.where('isProprietary', '==', true).where('platform', '==', platform), (accountsSnap) => {
        const accountDocs = accountsSnap.docs || []

        const accountListByPlatform = {}
        accountListByPlatform[platform] = accountDocs.map((accountDoc) => {
          const account = accountDoc.data() || {}
          const { capabilities = {} } = account
          if (!capabilities.video) return
          return account
        }).filter(account => account)

        commit('SET_ACCOUNT_LIST_BY_PLATFORM', accountListByPlatform)
      })

      commit('SET_ACCOUNTS_UNSUBSCRIBE', unsubscribe)
    },

    async GET_EXTERNAL_PLATFORMS_INFO({ commit }) {
      const { externalPlatformsInfo = {}, externalPlatformsOrderList = [] } = await performCloudFunction('getExternalPlatformsInfo')
      commit('SET_EXTERNAL_PLATFORMS_INFO', externalPlatformsInfo)
      commit('SET_EXTERNAL_PLATFORMS_ORDER_LIST', externalPlatformsOrderList)
    },

    TOGGLE_ACCOUNT_ENABLED({ rootState, state }, { platformId, accountId }) {
      const { signedInUserId } = rootState
      if (!signedInUserId) return

      const [account = {}] = (state.accountListByPlatform[platformId] || [])
        .filter(({ id }) => id === accountId)

      setDoc(USER_EXTERNAL_PLATFORM_ACCOUNT_DOC_PATH(signedInUserId, platformId, accountId), { multicastingEnabled: !account.multicastingEnabled })
    },
    SET_ACCOUNT_PRIVACY({ rootState }, { platformId, accountId, privacy }) {
      const { signedInUserId } = rootState
      if (!signedInUserId) return

      setDoc(USER_EXTERNAL_PLATFORM_ACCOUNT_DOC_PATH(signedInUserId, platformId, accountId), { privacy })
    },
  },
}
