import { datadogRum } from '@datadog/browser-rum'

import amplitude from 'amplitude-js'

import { isDev } from '@happstv/shared/util/utils'

/* eslint-disable */

// AMPLITUDE ANALYTICS
amplitude.getInstance().init(process.env.VUE_APP_IS_PROD === 'true' ? "9714126531a2781561e14e079f2caf20" : "0f64cdb69ba53c396374890a1c87194d", null, {
  includeReferrer: true,
  includeUtm: true,
  includeGclid: true,
});

// FACEBOOK PIXEL
const pixelId = isDev ? '781009425913609' : '244672203136658'

function getFacebookPixel() {
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');

  return fbq
}

// GOOGLE ANALYTICS
const gtmId = isDev ? 'GTM-5MW9XWX' : 'GTM-P6G23FP';

(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer',gtmId);

/* eslint-enable */

function amplitudeInstance() {
  return amplitude.getInstance()
}

const initState = () => ({
  facebookPixel: undefined,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    SET_FACEBOOK_PIXEL(state, value) {
      state.facebookPixel = value
    },
  },

  actions: {
    LOG_EVENT({ rootState }, { name, data = {} }) {
      const {
        deviceId,
        isMobile,
        happsAppOS,
        happsAppVersion,
        windowIsFocused,
        router = {},
      } = rootState

      Object.assign(data, {
        deviceId,
        isMobile,
        happsAppOS,
        happsAppVersion,
        windowIsFocused,
        currentRouteName: (router.currentRoute || {}).name,
        currentRouteParams: (router.currentRoute || {}).params,
        currentRouteQuery: (router.currentRoute || {}).query,
        url: window.location.href,
        isStarlightClient: true,
      })

      try {
        amplitudeInstance().logEvent(name, data)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Failed to log amplitude event.')
      }

      try {
        window.dataLayer.push({ event: name, ...data })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Failed to log firebase event.')
      }
    },

    SET_USER_ID(_, userId) {
      amplitudeInstance().setUserId(userId || null)
    },

    SET_USER_DATA({ commit }, user) {
      if (user) {
        // Datadog RUM
        datadogRum.setUser({
          id: user.id,
          name: (user.username || '').toLowerCase(),
        })

        const fbq = getFacebookPixel()
        fbq('init', pixelId, {
          fn: user.fullName,
          em: user.email,
          pn: user.phoneNumber,
          ct: (user.city || {}).fullName || '',
        })
        commit('SET_FACEBOOK_PIXEL', fbq)
      } else {
        // Logout user
        datadogRum.removeUser()
      }
    },

    LOG_PIXEL({ state, commit }, { name, data = {} }) {
      let fbq = state.facebookPixel
      if (!fbq) {
        fbq = getFacebookPixel()
        fbq('init', pixelId)
        commit('SET_FACEBOOK_PIXEL', fbq)
      }
      fbq('track', name, data)
    },

    LOG_GTAG(_, { name, data = {} }) {
      window.dataLayer.push({ event: name, ...data })
    },
  },
}
