var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PublicMediaKit', _vm._b({}, 'PublicMediaKit', {
    mediaKit: _vm.mediaKit
  }, false));
}
var staticRenderFns = []

export { render, staticRenderFns }