
import { getDoc } from '@happstv/shared/util/firebase/firestoreUtils'
import { USER_DOC_PATH } from '@happstv/shared/util/firebase/firestorePaths'
import performCloudFunction from '@/util/firebase/performCloudFunction'

const initState = () => ({
  referralInfo: undefined,
})

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    REINIT(state) {
      Object.assign(state, initState())
    },
    SET_REFERRAL_INFO(state, value) {
      state.referralInfo = value
    },
  },

  actions: {
    async VALIDATE_REFERRAL({ commit }, referralCode) {
      const validationResponse = await performCloudFunction('validateUserReferral', { referralCode })
      const { referrerUserId } = validationResponse
      if (!validationResponse.success || !referrerUserId) {
        const { error = 'An unknown error occurred.' } = validationResponse
        return { success: false, error }
      }

      const referrerUserDoc = await getDoc(USER_DOC_PATH(referrerUserId))
      const referrerUser = { id: referrerUserId, ...(referrerUserDoc.data() || {}) }

      commit('SET_REFERRAL_INFO', { referralCode, referrerUserId, referrerUser })
      return { success: true }
    },

    async COMPLETE_REFERRAL({ state, commit }) {
      const { referralCode, referrerUserId } = state.referralInfo || {}
      if (!referralCode || !referrerUserId) return

      const { success, error = 'An unknown error occurred' } = await performCloudFunction('completeUserReferral', { referralCode, referrerUserId })
      if (!success) return { success: false, error }
 
      commit('REINIT')
      return { success: true }
    },
  },
}
