
const Terms = () => import('@/pages/resources/Terms.vue')
const PrivacyPolicy = () => import('@/pages/resources/PrivacyPolicy.vue')

export default [
  {
    path: 'terms',
    name: 'terms',
    component: Terms,
    meta: {
      pageTitle: 'Terms of Service',
      isResource: true,
      partialAuthPermitted: true,
    },
  },
  {
    path: 'privacy',
    name: 'privacy',
    component: PrivacyPolicy,
    meta: {
      pageTitle: 'Privacy Policy',
      isResource: true,
      partialAuthPermitted: true,
    },
  },
]
