import {
  USER_DAILY_STAT_LOGS_COL_PATH,
  USER_VIEW_COUNT_LOGS_COL_PATH,
  USER_BROADCAST_STREAK_DOC_PATH,
  USER_SOCIAL_CONNECTIONS_LOGS_COL_PATH,
} from '@happstv/shared/util/firebase/firestorePaths'

import {
  watchDoc,
  watchCol,
  docIdKey,
} from '@happstv/shared/util/firebase/firestoreUtils'

import {
  ONE_DAY,
} from '@happstv/shared/util/timeConstants'

const {
  statLogDate,
} = require('@happstv/shared/util/utils')

const initState = () => ({
  historicalFollowerCounts: [],
  historicalFollowerCountsTrailingDays: 28,
  historicalViewCounts: [],
  broadcastStreak: 0,
  historicalSmsCount: 0,
  historicalUserPageViewCount: 0,
  followerLogsUnsubscribe: undefined,
  viewLogsUnsubscribe: undefined,
  broadcastStreakUnsubscribe: undefined,
  dailyStatLogsUnsubscribe: undefined,
})

export default {
  namespaced: true,

  state: initState(),

  getters: {
    followerCountChange(state, _, rootState) {
      const { historicalFollowerCounts } = state
      const { signedInUser = {} } = rootState
      const accountIds = Object.keys(signedInUser.connectedAccountsInfo || {})

      // Get the oldest logged value for each account currently connected
      const oldestFollowerCountById = accountIds.reduce((result, accountId) => {
        const [oldestLog] = historicalFollowerCounts.filter(log => Object.keys(log).includes(accountId)).reverse()
        if (oldestLog) return {...result, [accountId]: oldestLog[accountId]}
        return result
      }, {})

      // Get the newest logged value for each account currently connected
      const newestFollowerCountById = accountIds.reduce((result, accountId) => {
        const [newestLog] = historicalFollowerCounts.filter(log => Object.keys(log).includes(accountId))
        if (newestLog) return {...result, [accountId]: newestLog[accountId]}
        return result
      }, {})


      const oldFollowerCount = Object.values(oldestFollowerCountById).reduce((total, followerCount) => total + followerCount, 0)
      const newFollowerCount = Object.values(newestFollowerCountById).reduce((total, followerCount) => total + followerCount, 0)

      return newFollowerCount - oldFollowerCount
    },
    viewCountIncrease(state) {
      return state.historicalViewCounts.reduce((total, current) => total + current, 0)
    },
  },

  mutations: {
    REINIT(state) {
      Object.assign(state, initState())
    },
    SET_HISTORICAL_FOLLOWER_COUNTS(state, payload) {
      state.historicalFollowerCounts = payload
    },
    SET_HISTORICAL_VIEW_COUNTS(state, payload) {
      state.historicalViewCounts = payload
    },
    SET_BROADCAST_STREAK(state, payload) {
      state.broadcastStreak = payload
    },
    SET_HISTORICAL_SMS_FOLLOWER_COUNT(state, payload) {
      state.historicalSmsCount = payload
    },
    SET_HISTORICAL_USER_PAGE_VIEW_COUNT(state, payload) {
      state.historicalUserPageViewCount = payload
    },
    SET_FOLLOWER_LOGS_UNSUBSCRIBE(state, payload) {
      state.followerLogsUnsubscribe = payload
    },
    SET_VIEW_LOGS_UNSUBSCRIBE(state, payload) {
      state.viewLogsUnsubscribe = payload
    },
    SET_BROADCAST_STREAK_UNSUBSCRIBE(state, payload) {
      state.broadcastStreakUnsubscribe = payload
    },
    SET_DAILY_STAT_LOG_UNSUBSCRIBE(state, payload) {
      state.dailyStatLogsUnsubscribe = payload
    },
  },

  actions: {
    REINIT({ commit, state }) {
      if (state.followerLogsUnsubscribe) state.followerLogsUnsubscribe()
      if (state.viewLogsUnsubscribe) state.viewLogsUnsubscribe()
      if (state.broadcastStreakUnsubscribe) state.broadcastStreakUnsubscribe()
      commit('REINIT')
    },

    WATCH_USER_STATS({ commit, state, rootState }) {
      const { signedInUserId } = rootState
      if (!signedInUserId) return
      const { historicalFollowerCountsTrailingDays } = state

      const followerLogsUnsubscribe = watchCol(USER_SOCIAL_CONNECTIONS_LOGS_COL_PATH(signedInUserId), q => q.orderBy('logDate', 'desc').limit(historicalFollowerCountsTrailingDays), (followerLogsSnap) => {
        const followerLogsDocs = followerLogsSnap.docs || []
        const historicalFollowerCounts = followerLogsDocs.map(doc => (doc.data() || {}).followerCount || {})
        commit('SET_HISTORICAL_FOLLOWER_COUNTS', historicalFollowerCounts)
      })
      commit('SET_FOLLOWER_LOGS_UNSUBSCRIBE', followerLogsUnsubscribe)

      const viewLogsUnsubscribe = watchCol(USER_VIEW_COUNT_LOGS_COL_PATH(signedInUserId), q => q.orderBy('logDate', 'desc').limit(7), (viewLogsSnap) => {
        const viewLogsDocs = viewLogsSnap.docs || []
        const historicalViewCounts = viewLogsDocs.map(doc => (doc.data() || {}).viewCount || 0)
        commit('SET_HISTORICAL_VIEW_COUNTS', historicalViewCounts)
      })
      commit('SET_VIEW_LOGS_UNSUBSCRIBE', viewLogsUnsubscribe)

      const broadcastStreakUnsubscribe = watchDoc(USER_BROADCAST_STREAK_DOC_PATH(signedInUserId), (broadcastStreakDoc) => {
        const { currentStreakWeekCount = 0 } = broadcastStreakDoc.data() || {}
        commit('SET_BROADCAST_STREAK', currentStreakWeekCount)
      })
      commit('SET_BROADCAST_STREAK_UNSUBSCRIBE', broadcastStreakUnsubscribe)

      const todayTime = statLogDate(Date.now())
      const sevenDaysPriorTime = statLogDate(Date.now() - (7 * ONE_DAY))

      const dailyStatLogsUnsubscribe = watchCol(USER_DAILY_STAT_LOGS_COL_PATH(signedInUserId), q => q.orderBy(docIdKey()).startAt(sevenDaysPriorTime).endAt(todayTime), (dailyStatLogs) => {
        const dailyStatDocs = dailyStatLogs.docs || []
        const historicalSmsCount = dailyStatDocs.map(doc => (doc.data() || {}).smsUserFollowerCountChange || 0)
        const historicalSmsCountTotal = historicalSmsCount.reduce((prevVal, currVal) => prevVal + currVal, 0)
        const historicalUserPageViewCount = dailyStatDocs.map(doc => (doc.data() || {}).userPageViewCountChange || 0)
        const historicalUserPageViewCountTotal = historicalUserPageViewCount.reduce((prevVal, currVal) => prevVal + currVal, 0)
        commit('SET_HISTORICAL_SMS_FOLLOWER_COUNT', historicalSmsCountTotal)
        commit('SET_HISTORICAL_USER_PAGE_VIEW_COUNT', historicalUserPageViewCountTotal)
      })
      commit('SET_DAILY_STAT_LOG_UNSUBSCRIBE', dailyStatLogsUnsubscribe)
    },

    UNSUBSCRIBE_USER_STATS({ commit, state }) {
      if (state.followerLogsUnsubscribe) state.followerLogsUnsubscribe()
      if (state.viewLogsUnsubscribe) state.viewLogsUnsubscribe()
      if (state.broadcastStreakUnsubscribe) state.broadcastStreakUnsubscribe()
      if (state.dailyStatLogsUnsubscribe) state.dailyStatLogsUnsubscribe()
      commit('SET_FOLLOWER_LOGS_UNSUBSCRIBE', undefined)
      commit('SET_VIEW_LOGS_UNSUBSCRIBE', undefined)
      commit('SET_BROADCAST_STREAK_UNSUBSCRIBE', undefined)
      commit('SET_DAILY_STAT_LOG_UNSUBSCRIBE', undefined)
    },
  },
}
