
const UserSettings = () => import('@/pages/settings/UserSettingsPage.vue')
const UserSettingsProfile = () => import('@/pages/settings/UserSettingsProfilePage.vue')
const UserSettingsSecurity = () => import('@/pages/settings/UserSettingsSecurityPage.vue')
const UserSettingsConnected = () => import('@/pages/settings/UserSettingsConnectedPage.vue')
const UserSettingsSupport = () => import('@/pages/settings/UserSettingsSupportPage.vue')
const UserSettingsTerms = () => import('@/pages/settings/UserSettingsTermsPage.vue')

export default [
  {
    path: 'settings',
    name: 'userSettings',
    component: UserSettings,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: 'profile',
        name: 'userSettingsProfile',
        component: UserSettingsProfile,
        meta: {
          settingsTitle: 'Profile',
        },
      },
      {
        path: 'security',
        name: 'userSettingsSecurity',
        component: UserSettingsSecurity,
        meta: {
          settingsTitle: 'Security',
        },
      },
      {
        path: 'connected',
        name: 'userSettingsConnected',
        component: UserSettingsConnected,
        meta: {
          settingsTitle: 'Connected',
        },
      },
      {
        path: 'support',
        name: 'userSettingsSupport',
        component: UserSettingsSupport,
        meta: {
          settingsTitle: 'Support',
        },
      },
      {
        path: 'terms',
        name: 'userSettingsTerms',
        component: UserSettingsTerms,
        meta: {
          settingsTitle: 'Terms',
        },
      },
    ],
  },
]
