//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import { getCol } from '@happstv/shared/util/firebase/firestoreUtils'
import { USERS_COL_PATH } from '@happstv/shared/util/firebase/firestorePaths'
import { makeReachUrl } from '@happstv/shared/util/makeReachUrl'
import { makeReachDynamicLink } from '@happstv/shared/util/makeReachDynamicLink'

import PublicPageFrame from '@/components/universal/layout/PublicPageFrame.vue'
import GoogleBadge from '@/assets/svg/app/GoogleBadge.vue'
import AppleBadge from '@/assets/svg/app/AppleBadge.vue'

export default {
  name: 'WaitlistInvitePage',
  components: {
    PublicPageFrame,
    GoogleBadge,
    AppleBadge,
  },
  data() {
    return {
      inviteUser: undefined,
    }
  },
  computed: {
    inviteUsername() {
      return this.$route.params.inviteUsername
    },
    imageSrc() {
      const { profilePhotoPath } = this.inviteUser
      const defaultImgPath = '/img/no_profile_photo.png'
      return profilePhotoPath || defaultImgPath
    },
    universalLink() {
      const { inviteUsername } = this
      return makeReachUrl(`route/waitlistInvite?params=${encodeURIComponent(JSON.stringify({ inviteUsername }))}`, { isJoin: true })
    },
    googlePlayDynamicLink() {
      return makeReachDynamicLink(this.universalLink, { fallbackToGooglePlay: true, analytics: { campaign: `invite-${this.inviteUsername}` } })
    },
    appStoreDynamicLink() {
      return makeReachDynamicLink(this.universalLink, { fallbackToAppStore: true, analytics: { campaign: `invite-${this.inviteUsername}` } })
    },
  },
  watch: {
    inviteUsername: {
      immediate: true,
      async handler(newValue) {
        this.inviteUser = undefined

        const inviteUserSnap = await getCol(USERS_COL_PATH, q => q.where('lowercaseUsername', '==', newValue.toLowerCase()).limit(1))
        const [inviteUserDoc = {}] = inviteUserSnap.docs || []

        if (!inviteUserDoc.exists) {
          this.$router.replace({ name: 'home' })
          return
        }

        this.inviteUser = { id: inviteUserDoc.id, ...(inviteUserDoc.data() || {}) }
      },
    },
  },
  methods: {
    ...mapActions('analytics', [
      'LOG_EVENT',
    ]),
  },
}
