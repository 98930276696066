
import Vuex from 'vuex'
import firebase from 'firebase/app'

import { uniformLength, isDev } from '@happstv/shared/util/utils'

import { cloudTimestamp, setDoc, watchDoc } from '@happstv/shared/util/firebase/firestoreUtils'
import { USER_DOC_PATH, USER_PRIVATE_DATA_DOC_PATH } from '@happstv/shared/util/firebase/firestorePaths'

import { TOP_NAV_HEIGHT } from '@/constants/app/appConstants'

import performCloudFunction from '@/util/firebase/performCloudFunction'

// app
import overlays from '@/store/app/overlays'
import analytics from '@/store/app/analytics'
import pushNotifications from '@/store/app/pushNotifications'
import logs from '@/store/app/logs'

// auth
import auth from '@/store/auth/auth'
import referral from '@/store/auth/referral'

// user
import userBlocks from '@/store/user/userBlocks'
import userStats from '@/store/user/userStats'
import communityUser from '@/store/user/communityUser'
import communityUserFollows from './user/communityUserFollows'

// agent
import agent from '@/store/agent/agent'

// dms
import dms from '@/store/dms/dms'

// connected accounts
import connectedAccounts from '@/store/connectedAccounts/connectedAccounts'
import errors from './connectedAccounts/errors'

// settings
import insightsFeed from '@/store/settings/insightsFeed'
import userPageSettings from '@/store/settings/userPageSettings'

// search
import search from '@/store/search/search'

// console
import consoleBrands from '@/store/console/consoleBrands'
import consoleBrandCampaignUser from '@/store/console/consoleBrandCampaignUser'

// dashboard
import brandCampaignDashboard from '@/store/dashboard/brandCampaignDashboard'

let fileDragCounter = 0
let pageTitle = 'Reach'

const store = new Vuex.Store({
  modules: {
    overlays,
    analytics,
    pushNotifications,
    logs,
    auth,
    referral,
    userBlocks,
    userStats,
    userPageSettings,
    communityUser,
    connectedAccounts,
    errors,
    insightsFeed,
    communityUserFollows,
    search,
    consoleBrands,
    consoleBrandCampaignUser,
    agent,
    dms,
    brandCampaignDashboard,
  },

  state: {
    waitingAuthPromise: undefined,
    waitingAuth: true,
    signedInFirebaseUser: undefined,
    signedInUser: undefined,
    signedInUserId: false, // false while waiting auth, then undefined or a string
    signedInUserPrivateData: undefined,
    signedInUserUnsubscribe: undefined,
    signedInUserPrivateDataUnsubscribe: undefined,
    deviceId: undefined,
    isDev,
    isMobile: false,
    isWebview: false,
    happsAppOS: undefined,
    happsAppVersion: undefined,
    fileDrag: false,
    headerTranslation: 0,
    unmutedAutoplayAllowed: false,
    // router: undefined,
    currentRoute: undefined,
    onFirstRoute: undefined,
    navigatingToRoute: false,
    isFullscreen: Boolean(document.fullscreenElement),
    windowIsFocused: true,
  },

  getters: {
    topCssUnderTopNav(state) {
      return `${-state.headerTranslation + TOP_NAV_HEIGHT}px`
    },
  },

  mutations: {
    SET_SIGNED_IN_FIREBASE_USER(state, value) {
      state.signedInFirebaseUser = value
    },
    SET_SIGNED_IN_USER(state, value) {
      state.waitingAuth = false
      state.signedInUser = value
      const signedInUserId = (value || {}).id || undefined
      if (signedInUserId !== state.signedInUserId) state.signedInUserId = signedInUserId
    },
    SET_SIGNED_IN_USER_UNSUBSCRIBE(state, payload) {
      if (state.signedInUserUnsubscribe) state.signedInUserUnsubscribe()
      state.signedInUserUnsubscribe = payload
    },
    SET_SIGNED_IN_USER_PRIVATE_DATA(state, payload) {
      state.signedInUserPrivateData = payload
    },
    SET_SIGNED_IN_USER_PRIVATE_DATA_UNSUBSCRIBE(state, payload) {
      if (state.signedInUserPrivateDataUnsubscribe) state.signedInUserPrivateDataUnsubscribe()
      state.signedInUserPrivateDataUnsubscribe = payload
    },
    SET_DEVICE_ID(state, payload) {
      state.deviceId = payload
    },
    SET_ROUTER(state, payload) {
      state.router = payload
    },
    SET_CURRENT_ROUTE(state, payload) {
      state.currentRoute = payload
    },
    SET_IS_MOBILE(state, payload) {
      state.isMobile = payload
    },
    SET_IS_WEBVIEW(state, payload) {
      state.isWebview = payload
    },
    SET_FILE_DRAG(state, payload) {
      state.fileDrag = payload
    },

    SET_UNMUTED_AUTOPLAY_ALLOWED(state) {
      state.unmutedAutoplayAllowed = true
    },

    SET_NAVIGATING_TO_ROUTE(state, value) {
      state.navigatingToRoute = value
    },
    SET_WINDOW_IS_FOCUSED(state, value) {
      state.windowIsFocused = value
    },
  },

  actions: {
    INIT_DEVICE_ID({ commit }) {
      let deviceId = localStorage.getItem('deviceId')
      if (!deviceId) {
        deviceId = `b_${uniformLength(10, new Date().getTime().toString(36).toLowerCase())}_${uniformLength(6, Math.floor(Math.random() * 2176782336).toString(36).toLowerCase())}`
        localStorage.setItem('deviceId', deviceId)
      }
      commit('SET_DEVICE_ID', deviceId)
    },

    ON_ROUTER_RESOLVED({ commit, state }, f) {
      if (state.router) f(state.router)
      else commit('ADD_ON_ROUTER_RESOLVED_FUNCTION', f)
    },

    async GET_REDIRECT_FROM_ROUTE_IF_NECESSARY({ state, dispatch }, { route }) {
      const query = Object.assign({}, route.query || {})
      let queryChanged = false

      if (state.onFirstRoute) state.onFirstRoute(route)

      if (!route.matched.some(record => record.meta.authIndependent)) {
        const { token } = query
        if (token) {
          delete query.token
          queryChanged = true
        }
      }

      const { consoleLogsAwsParams, consoleLogsAwsCredentials } = query
      if (consoleLogsAwsParams && consoleLogsAwsCredentials) {
        delete query.consoleLogsAwsParams
        delete query.consoleLogsAwsCredentials
        queryChanged = true

        const [logGroupName, logStreamName] = decodeURIComponent(consoleLogsAwsParams).split(',')
        const [accessKeyId, secretAccessKey] = decodeURIComponent(consoleLogsAwsCredentials).split(',')

        dispatch('logs/START_AWS_CONSOLE_LOG_UPLOAD', {
          logGroupName,
          logStreamName,
          credentials: {
            accessKeyId,
            secretAccessKey,
          },
        })
      }

      const { pushData } = query
      if (pushData) {
        delete query.pushData
        queryChanged = true

        dispatch('analytics/LOG_EVENT', {
          name: 'push_notification_clicked',
          data: {
            ...(typeof pushData === 'object' ? pushData : {}),
          },
        })

        const { notificationId } = pushData
        if (notificationId) performCloudFunction('setCommunityNotificationOpenedAndRead', { notificationId, isPush: true, os: 'b' })
      }

      if (queryChanged) return Object.assign({}, route, { query })

      return undefined
    },

    async SET_SIGNED_IN_USER({ commit, state, dispatch }, value) {
      const newUserId = (state.signedInUser || {}).id !== (value || {}).id

      commit('SET_SIGNED_IN_USER', value)

      if (newUserId) {
        commit('auth/REINIT')
        dispatch('analytics/SET_USER_ID', (value || {}).id)
      }

      if (value) {
        value.email = (firebase.auth().currentUser || {}).email || ''
        value.phoneNumber = (firebase.auth().currentUser || {}).phoneNumber || ''
        dispatch('analytics/SET_USER_DATA', value)
      } else {
        dispatch('analytics/SET_USER_DATA')
      }

      const { currentRoute } = state.router
      const redirect = await dispatch('GET_REDIRECT_FROM_ROUTE_IF_NECESSARY', { route: currentRoute })

      if (redirect) state.router.push(redirect)
    },

    async SET_SIGNED_IN_USER_LISTENER({ commit, dispatch }, { firebaseUser, resolve = (() => { }) }) {
      commit('SET_SIGNED_IN_USER_UNSUBSCRIBE', undefined)
      commit('SET_SIGNED_IN_USER_PRIVATE_DATA_UNSUBSCRIBE', undefined)

      commit('SET_SIGNED_IN_FIREBASE_USER', firebaseUser)

      if (!firebaseUser) {
        commit('SET_SIGNED_IN_USER_PRIVATE_DATA', undefined)
        await dispatch('SET_SIGNED_IN_USER', undefined)
        resolve(true)

        // this event must be after SET_SIGNED_IN_USER, so new user state is propagated
        dispatch('analytics/LOG_EVENT', { name: 'auth_state_changed' })
        return
      }

      let userId = firebaseUser.uid


      let firstReturn = true
      const signedInUserUnsubscribe = watchDoc(USER_DOC_PATH(userId), async (doc) => {
        const signedInUser = doc.data() || {}

        if (!doc.exists || !signedInUser) {
          await dispatch('SET_SIGNED_IN_USER', undefined)
          resolve(true)
          return
        }

        await dispatch('SET_SIGNED_IN_USER', { ...signedInUser, id: doc.id })
        resolve(true)

        if (firstReturn) {
          firstReturn = false
          // this event must be after SET_SIGNED_IN_USER, so new user state is propagated
          dispatch('analytics/LOG_EVENT', { name: 'auth_state_changed' })
          await setDoc(USER_DOC_PATH(userId), {
            latestWebSessionDate: cloudTimestamp(),
          })
        }
      })
      commit('SET_SIGNED_IN_USER_UNSUBSCRIBE', signedInUserUnsubscribe)

      const signedInUserPrivateDataUnsubscribe = watchDoc(USER_PRIVATE_DATA_DOC_PATH(userId), (doc) => {
        const privateData = doc.data() || {}
        commit('SET_SIGNED_IN_USER_PRIVATE_DATA', privateData)
      })
      commit('SET_SIGNED_IN_USER_PRIVATE_DATA_UNSUBSCRIBE', signedInUserPrivateDataUnsubscribe)
    },

    SET_IS_MOBILE({ commit }, value) {
      commit('SET_IS_MOBILE', value)
    },

    SET_IS_WEBVIEW({ commit }, value) {
      commit('SET_IS_WEBVIEW', value)
    },

    FILE_DRAG_ENTER({ commit, state }) {
      fileDragCounter += 1
      if (!state.fileDrag) commit('SET_FILE_DRAG', true)
    },

    FILE_DRAG_EXIT({ dispatch }) {
      fileDragCounter -= 1
      if (fileDragCounter <= 0) dispatch('END_FILE_DRAG')
    },

    END_FILE_DRAG({ commit, state }) {
      fileDragCounter = 0
      if (state.fileDrag) commit('SET_FILE_DRAG', false)
    },

    UPDATE_PAGE_TITLE(_, { title, resetTitle } = {}) {
      if (title) pageTitle = `${title} - Reach`
      if (resetTitle) pageTitle = 'Reach'

      document.title = pageTitle
    },
  },
})

let tokenAuthPromise
store.state.waitingAuthPromise = new Promise((resolve) => {
  store.state.onFirstRoute = (route) => {
    const { token } = route.query || {}
    store.state.onFirstRoute = undefined

    firebase.auth().onAuthStateChanged(async () => {
      if (token && !route.matched.some(record => record.meta.authIndependent)) {
        tokenAuthPromise = firebase.auth().signInWithCustomToken(token)
      }

      try {
        await tokenAuthPromise
        // eslint-disable-next-line
      } catch (e) { console.warn(e) }

      let { currentUser } = firebase.auth()
      if ((currentUser || {}).isAnonymous) currentUser = undefined
      if (!(currentUser || {}).phoneNumber) currentUser = undefined

      store.dispatch('SET_SIGNED_IN_USER_LISTENER', { firebaseUser: currentUser, resolve })
    })
  }
})

document.onfullscreenchange = () => {
  store.state.isFullscreen = Boolean(document.fullscreenElement)
}
document.onmozfullscreenchange = () => {
  store.state.isFullscreen = Boolean(document.mozFullscreenElement)
}
document.onwebkitfullscreenchange = () => {
  store.state.isFullscreen = Boolean(document.webkitFullscreenElement)
}
document.onmsfullscreenchange = () => {
  store.state.isFullscreen = Boolean(document.msFullscreenElement)
}
window.onfocus = () => {
  store.commit('SET_WINDOW_IS_FOCUSED', true)
}
window.onblur = () => {
  store.commit('SET_WINDOW_IS_FOCUSED', false)
}

export default store
