import {
  USER_EXTERNAL_PLATFORM_ACCOUNT_DOC_PATH,
  USER_EXTERNAL_PLATFORMS_COL_PATH,
  USER_EXTERNAL_PLATFORM_ACCOUNTS_COL_PATH,
  EXTERNAL_PLATFORM_ACCOUNT_DOC_PATH,
} from '@happstv/shared/util/firebase/firestorePaths'

import { 
  getCol,
  getDoc,
  getColGroup,
} from '@happstv/shared/util/firebase/firestoreUtils'

const initState = () => ({
  accountErrors: null,
  accountList: [],
  accountInfo: null,
  channelId: null,
  errorQueryType: null,
  lastErrorDoc: undefined,
  paginationLoading: true,
  endOfFeed: false,
  latestResolution: null,
})

const ERROR_TYPE_USER = 'ERROR_TYPE_USER'
const ERROR_TYPE_ACCOUNT = 'ERROR_TYPE_ACCOUNT'
const ERROR_TYPE_PROPRIETARY = 'ERROR_TYPE_PROPRIETARY'
const ERROR_TYPE_USER_FACING = 'ERROR_TYPE_USER_FACING'

export default {
  namespaced: true,
  state: {
    ...initState(),
  },
  getters: {
    getRelevantAccountDocPath(state) {
      const { accountId, platformId, userId } = state.accountInfo
      const type = state.errorQueryType
      const firestorePath = (type === ERROR_TYPE_ACCOUNT || type === ERROR_TYPE_USER_FACING)
        ? USER_EXTERNAL_PLATFORM_ACCOUNT_DOC_PATH(userId, platformId, accountId)
        : EXTERNAL_PLATFORM_ACCOUNT_DOC_PATH(platformId, accountId)
      return firestorePath
    },
    getRelevantAccountErrorQuery(state){
      const { accountId, platformId } = state.accountInfo
      const type = state.errorQueryType
      return (query) => {
        return (type === ERROR_TYPE_PROPRIETARY)
          ? getCol(`${EXTERNAL_PLATFORM_ACCOUNT_DOC_PATH(platformId, accountId)}/accountErrorLog`, query)
          : getColGroup('accountErrorLog', query)
      }
    },
    async getInitialQuery(state) {
      if (state.accountInfo) {
        const { accountId, userId } = state.accountInfo
        const channelId = state.channelId
        const type = state.errorQueryType
        const lastErrorResolution = state.latestResolution
        let channelQuery = undefined
        switch (type) {
          case ERROR_TYPE_USER: {
            let queryAddition = q => q
              .where('userId', '==', userId)
              .orderBy('errorDate', 'desc')
              .limit(10)
            if (channelId) channelQuery = q => queryAddition(q).where('channelId', '==', channelId)
            return channelQuery || queryAddition
          }
          case ERROR_TYPE_ACCOUNT: {
            let queryAddition = q => q
              .where('userId', '==', userId)
              .where('accountId', '==', accountId)
              .orderBy('errorDate', 'desc')
              .limit(10)
            if (channelId) channelQuery = q => queryAddition(q).where('channelId', '==', channelId)
            return channelQuery || queryAddition
          }
          case ERROR_TYPE_PROPRIETARY: {
            let queryAddition = q => q
              .orderBy('errorDate', 'desc')
              .limit(10)
            if (channelId) channelQuery = q => queryAddition(q).where('channelId', '==', channelId)
            return channelQuery || queryAddition
          }
          default:
            return q => q
              .where('userId', '==', userId)
              .where('accountId', '==', accountId)
              .where('errorDate', '>', lastErrorResolution)
              .orderBy('errorDate', 'desc')
              .limit(10)
        }
      }
    },
    async getAfterQuery(state, getters) {
      const { getInitialQuery } = getters
      const initial = await getInitialQuery
      return q => initial(q).startAfter(state.lastErrorDoc || null)
    },
  },
  mutations: {
    REINIT(state) {
      if (state.resolutionUnsubscribe) {
        state.resolutionUnsubscribe()
      }
      Object.assign(state, initState())
    },
    SET_ACCOUNT_ERRORS(state, payload) {
      state.accountErrors = payload
    },
    SET_ACCOUNT_LIST(state, payload) {
      state.accountList = payload
    },
    SET_CHANNEL_ID(state, payload) {
      state.channelId = payload
    },
    SET_LAST_ERROR_DOC(state, payload) {
      state.lastErrorDoc = payload
    },
    SET_LOADING(state, payload) {
      state.paginationLoading = payload
    },
    SET_END_OF_FEED(state, payload) {
      state.endOfFeed = payload
    },
    SET_LATEST_RESOLUTION(state, payload) {
      state.latestResolution = payload
    },
    SET_ACCOUNT_INFO(state, payload) {
      state.accountInfo = payload
    },
    SET_ERROR_QUERY_TYPE(state, payload) {
      state.errorQueryType = payload
    },
  },
  actions: {
    REINIT({ commit }) {
      commit('REINIT')
    },
    async WATCH_ERRORS({ commit, state, getters }) {
      const { accountId, platformId, userId } = state.accountInfo
      const type = state.errorQueryType
      let accountList = []
      let accountErrors = []
      let errorSnap
      let displayName
      const docSnap = await getDoc(getters.getRelevantAccountDocPath)
      const accountData = docSnap.data() || {}
      const lastErrorResolution = accountData.latestErrorResolutionDate || new Date(0)
      commit('SET_LATEST_RESOLUTION', lastErrorResolution)
  
      // Set loading state to true
      commit('SET_LOADING', true)
      switch (type) {
        case ERROR_TYPE_USER: {
          // Since we are getting all user errors, we need to populate accountList with all connected accounts
          const externalPlatformsSnap = await getCol(USER_EXTERNAL_PLATFORMS_COL_PATH(userId))
          const platforms = externalPlatformsSnap.docs || []
          platforms.forEach(async (platform) => {
            const externalPlatformsAccountsSnap = await getCol(USER_EXTERNAL_PLATFORM_ACCOUNTS_COL_PATH(userId, platform.id))
            const externalPlatformsAccounts = externalPlatformsAccountsSnap.docs || []
            externalPlatformsAccounts.forEach((account) => {
              const accountData = account.data() || {}
              const listAccountId = accountData.id || null
              const listPlatformId = accountData.platform || null
              const listLastResolved = accountData.latestErrorResolutionDate || null
              accountList.push({ listAccountId, listPlatformId, listLastResolved })
            })
          })
          break
        }
        default: {
          accountList = [{ listAccountId: accountId, listPlatformId: platformId }]
          break
        }
      }
      const queryForErrors = getters.getRelevantAccountErrorQuery
      errorSnap = await queryForErrors((state.lastErrorDoc) ? await getters.getAfterQuery : await getters.getInitialQuery)
  
      if ((type === ERROR_TYPE_ACCOUNT || type === ERROR_TYPE_PROPRIETARY) && accountData) {
        // Get account data to pull the display name
        displayName = accountData.displayName || null
        accountList[0]['listDisplayName'] = displayName
      }
      
      // Store the error docs
      const accountErrorDocs = errorSnap.docs || []
      // Set the last doc in the current doc array as the last doc to be used next time
      commit('SET_LAST_ERROR_DOC', accountErrorDocs[accountErrorDocs.length - 1])
      if (accountErrorDocs.length < 10 && accountErrorDocs.length > 0){
        // If the current collection query yielded less than 10 results, we are at the end of the feed
        commit('SET_END_OF_FEED', true)
      }
      if (accountErrorDocs.length == 0) { 
        // If the current collection query yielded no results, we are at the end of the feed
        commit('SET_END_OF_FEED', true)
        if (state.lastErrorDoc) {
          // We only want to return if we have already populated some data otherwise it will endlessly load without displaying "no errors" message
          return
        }
      }
      // Push current errors to array if they exist before new ones
      if (state.accountErrors) {
        accountErrors.push(...state.accountErrors)
      }
      // Loop through error docs
      accountErrorDocs.forEach((doc) => {
        // Store the error's doc id
        const docId = doc.id
        const docData = doc.data() || {}
        const { accountId = null, platform = null, errorDate = null } = docData
        docData['errorId'] = docId
        if (type === ERROR_TYPE_USER) {
          // Find the last error resolution for the specific account and set a resolved flag
          const selectedAccount = accountList.filter(obj => (obj.listAccountId == accountId) && (obj.listPlatformId == platform))[0]
          if (selectedAccount) {
            const { listLastResolved = new Date(0) } = selectedAccount
            docData['resolved'] = (listLastResolved > errorDate)
          }
        } else {
          // Set a resolved flag based on last resolution
          docData['resolved'] = (state.latestResolution > errorDate)
        }
        accountErrors.push(docData)
      })
      commit('SET_LOADING', false)
      commit('SET_ACCOUNT_LIST', accountList)
      commit('SET_ACCOUNT_ERRORS', accountErrors)
    },
    async WATCH_ERRORS_ACCOUNT_ID({ commit, dispatch }, { accountId, platformId, userId, channelId = null }) {
      commit('SET_ACCOUNT_INFO', { accountId, platformId, userId })
      commit('SET_ERROR_QUERY_TYPE', ERROR_TYPE_ACCOUNT)
      commit('SET_CHANNEL_ID', channelId)
      dispatch('WATCH_ERRORS')
    },
    async WATCH_ERRORS_USER_ID({ commit, dispatch }, { userId, channelId = null }) {
      commit('SET_ACCOUNT_INFO', { userId })
      commit('SET_ERROR_QUERY_TYPE', ERROR_TYPE_USER)
      commit('SET_CHANNEL_ID', channelId)
      dispatch('WATCH_ERRORS')
    },
    async WATCH_ERRORS_PROPRIETARY({ commit, dispatch }, { accountId, platformId, channelId = null }) {
      commit('SET_ACCOUNT_INFO', { accountId, platformId })
      commit('SET_ERROR_QUERY_TYPE', ERROR_TYPE_PROPRIETARY)
      commit('SET_CHANNEL_ID', channelId)
      dispatch('WATCH_ERRORS')
    },
    async WATCH_ERRORS_USER_FACING({ commit, dispatch }, { accountId, platformId, userId }) {
      commit('SET_ACCOUNT_INFO', { accountId, platformId, userId })
      commit('SET_ERROR_QUERY_TYPE', ERROR_TYPE_USER_FACING)
      dispatch('WATCH_ERRORS')
    },
  },
}
