
import firebase from 'firebase'

const AuthStartOverStep = () => import('@/components/auth/steps/AuthStartOverStep.vue')
const AuthCloseStep = () => import('@/components/auth/steps/AuthCloseStep.vue')
const AuthLoadingStep = () => import('@/components/auth/steps/AuthLoadingStep.vue')
const AuthReferralInfoStep = () => import('@/components/auth/steps/AuthReferralInfoStep.vue')
const AuthPhoneStep = () => import('@/components/auth/steps/AuthPhoneStep.vue')
const AuthSmsCodeStep = () => import('@/components/auth/steps/AuthSmsCodeStep.vue')
const AuthNameStep = () => import('@/components/auth/steps/AuthNameStep.vue')
const AuthEmailStep = () => import('@/components/auth/steps/AuthEmailStep.vue')
const AuthPhotoStep = () => import('@/components/auth/steps/AuthPhotoStep.vue')

const initState = () => ({
  userRequestedAuth: false,
  smsConfirmation: undefined,
  nameSetRecently: false,
  currentPhone: undefined,
})

export default {
  namespaced: true,

  state: initState(),

  getters: {
    authStepComponentList(state, getters, rootState) {
      const {
        smsConfirmation,
        nameSetRecently,
      } = state

      const { inPaymentsAuthFlow } = getters

      const {
        waitingAuth,
        signedInFirebaseUser,
        signedInUser,
        signedInUserPrivateData,
      } = rootState

      const {
        referralInfo,
      } = rootState.referral

      if (waitingAuth) {
        return []
      }

      const steps = []
      if (signedInFirebaseUser) steps.push({ key: 'startOver', component: AuthStartOverStep })
      else steps.push({ key: 'closeAuth', component: AuthCloseStep })
      if (referralInfo) steps.push({ key: 'referralInfo', component: AuthReferralInfoStep })
      steps.push({ key: 'phone', component: AuthPhoneStep })
      if (!smsConfirmation && !signedInFirebaseUser) {
        return steps
      }
      steps.push({ key: 'smsCode', component: AuthSmsCodeStep })
      if (!signedInUser || signedInUserPrivateData === undefined) {
        if (signedInFirebaseUser) steps.push({ key: 'loadingUser', component: AuthLoadingStep })
        return steps
      }
      steps.push({ key: 'name', component: AuthNameStep })
      if (!signedInUser.fullName) {
        return steps
      }
      if(!signedInUser.approvedForStarlight && referralInfo) { // this is before the email step, because the email step also asks for IG username if not approved
        steps.push({ key: 'loadingReferralCompletion', component: AuthLoadingStep })
        return steps
      }
      steps.push({ key: 'email', component: AuthEmailStep })
      if (!signedInUserPrivateData.email || inPaymentsAuthFlow) {
        return steps
      }
      if (!signedInUser.profilePhotoPath && !signedInUser.onboardingCompletionDate) {
        steps.push({ key: 'photo', component: AuthPhotoStep })
        return steps
      }
      if (nameSetRecently && !signedInUser.username) {
        if (signedInFirebaseUser) steps.push({ key: 'loadingUsername', component: AuthLoadingStep })
        return steps
      }
      return []
    },

    matchedRoutes(_, __, rootState) {
      const { currentRoute = {} } = rootState
      const { matched = [] } = currentRoute
      return matched
    },
    inAuth(_, getters) {
      return getters.matchedRoutes.some(({ name }) => name === 'auth' || name === 'inviteSignUp')
    },
    inPaymentsAuthFlow(_, getters) {
      return getters.matchedRoutes.some(({ name }) => name === 'paymentsSplash')
    },
    pageIsAuthIndependent(_, getters) {
      return getters.matchedRoutes.some(({ meta }) => meta.authIndependent)
    },
    pageRequiresAuthRoles(_, getters) {
      return getters.matchedRoutes.map(({ meta }) => meta.role).filter(role => role)
    },
    pageRequiresAuth(_, getters) {
      if (getters.pageRequiresAuthRoles.length) return true
      return getters.matchedRoutes.reduce((prevResult, { meta }) => (meta.authRequired !== undefined ? meta.authRequired : prevResult), undefined)
    },
    pageInsufficientAuthRedirect(_, getters) {
      const route = getters.matchedRoutes.reduce((prevResult, { meta }) => meta.insufficientAuthRedirect || prevResult, undefined)
      if (route) return route
      return { name: 'home' }
    },
    pageRequiresNoAuth(_, getters) {
      return getters.matchedRoutes.some(({ meta }) => meta.noAuthRequired)
    },
    pagePermitsPartialAuth(_, getters) {
      const { matchedRoutes, pageRequiresAuth } = getters
      if (pageRequiresAuth) return false
      return matchedRoutes.reduce((prevResult, { meta }) => (meta.partialAuthPermitted !== undefined ? meta.partialAuthPermitted : prevResult), undefined)
    },

    authRedirect(state, getters, rootState) {
      const {
        userRequestedAuth,
      } = state

      const {
        inAuth,
        pageIsAuthIndependent,
        pageRequiresAuth,
        pageRequiresAuthRoles,
        pageInsufficientAuthRedirect,
        pageRequiresNoAuth,
        pagePermitsPartialAuth,
        authStepComponentList,
      } = getters

      const {
        waitingAuth,
        signedInUser,
        signedInUserPrivateData,
        currentRoute = {},
      } = rootState

      const { href, origin } = window.location
      const hrefWithoutOrigin = href.replace(origin, '')
      const authPageRoute = { name: 'auth', query: { auth_redirect: hrefWithoutOrigin } }

      const waitingPrivateData = signedInUser && (signedInUserPrivateData === undefined)

      if (pageIsAuthIndependent) {
        return undefined
      }

      if (waitingAuth || waitingPrivateData) {
        if (pageRequiresAuth || pageRequiresNoAuth || pageRequiresAuthRoles.length) return { maybe: true }
        return undefined
      }

      if (signedInUser) {
        if (authStepComponentList && authStepComponentList.length) {
          if (!inAuth && !pagePermitsPartialAuth) return { route: authPageRoute, addRouterHistory: false }
          return undefined
        }
        if (inAuth || pageRequiresNoAuth) {
          const authRedirect = currentRoute.query.auth_redirect
          const redirectRoute = authRedirect ? { path: authRedirect } : { name: 'home' }
          return { route: redirectRoute }
        }
        if (pageRequiresAuthRoles.some(role => !signedInUser[`${role}Permission`])) {
          return { route: pageInsufficientAuthRedirect }
        }
      } else {
        if (pageRequiresAuth || (userRequestedAuth && !inAuth)) {
          return { route: authPageRoute, addRouterHistory: userRequestedAuth }
        }
      }

      return undefined
    },
  },

  mutations: {
    REINIT(state) {
      Object.assign(state, initState())
    },
    SET_USER_REQUESTED_AUTH(state, value) {
      state.userRequestedAuth = value
    },
    SET_SMS_CONFIRMATION(state, value) {
      state.smsConfirmation = value
    },
    SET_NAME_SET_RECENTLY(state, value) {
      state.nameSetRecently = value
    },
    SET_CURRENT_PHONE(state, value) {
      state.currentPhone = value
    },
  },

  actions: {
    async SIGN_OUT() {
      await firebase.auth().signOut()
    },

    async CREATE_AUTH_RECAPTCHA(_, { authButton }) {
      return await new firebase.auth.RecaptchaVerifier(authButton, {
        size: 'invisible',
      })
    },
    async SEND_AUTH_SMS({ commit }, { phone, recaptchaVerifier }) {
      commit('SET_SMS_CONFIRMATION', undefined)

      try {
        const smsConfirmation = await firebase.auth().signInWithPhoneNumber(phone, recaptchaVerifier)
        commit('SET_SMS_CONFIRMATION', smsConfirmation)
        return { success: true }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e.code, e)
        switch (e.code) {
          case 'auth/captcha-check-failed':
            return { success: false, error: 'Failed to establish secure connection.' }
          case 'auth/missing-phone-number':
          case 'auth/invalid-phone-number':
            return { success: false, error: 'Phone number is invalid.' }
          default:
            return { success: false, error: e.message || 'Something went wrong.' }
        }
      }
    },
    async CONFIRM_AUTH_SMS_CODE({ state }, { code }) {
      const { smsConfirmation } = state

      if (!smsConfirmation) return { success: false, error: 'Confirmation corrupted, please refresh page.' }

      try {
        await smsConfirmation.confirm(code)
        return { success: true }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e.code)
        switch (e.code) {
          case 'auth/invalid-verification-code':
            return { success: false, error: 'Invalid code. Please try again.' }
          default:
            return { success: false, error: e.message || 'Something went wrong.' }
        }
      }
    },
    async SIGN_IN_WITH_TOKEN(_, token) {
      try {
        await firebase.auth().signInWithCustomToken(token)
        // eslint-disable-next-line
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e.message)
        return { success: false, error: e.message }
      }

      return { success: true }
    },
  },
}
