import Vue from 'vue'

import {
  watchDoc,
} from '@happstv/shared/util/firebase/firestoreUtils'

import {
  USER_BLOCKED_USER_DOC_PATH,
} from '@happstv/shared/util/firebase/firestorePaths'

import performCloudFunction from '@/util/firebase/performCloudFunction'

const personalState = () => ({
  userBlockersByUserId: {},
  userBlockingByUserId: {},
  userBlockersUnsubscribesByUserId: {},
  userBlockingUnsubscribesByUserId: {},
})

const uiState = () => ({
  userBlockersWatchCountsByUserId: {},
  userBlockingWatchCountsByUserId: {},
})

export default {
  namespaced: true,

  state: {
    ...personalState(),
    ...uiState(),
  },

  mutations: {
    REINIT(state) {
      Object.values(state.userBlockersUnsubscribesByUserId).filter(u => u).forEach(u => u())
      Object.values(state.userBlockingUnsubscribesByUserId).filter(u => u).forEach(u => u())

      Object.assign(state, personalState())
    },

    SET_BLOCKER_DATA(state, { blockerUserId, data }) {
      Vue.set(state.userBlockersByUserId, blockerUserId, data)
    },
    SET_BLOCKING_DATA(state, { blockingUserId, data }) {
      Vue.set(state.userBlockingByUserId, blockingUserId, data)
    },
    SET_BLOCKER_UNSUBSCRIBE(state, { blockerUserId, unsubscribe }) {
      if (state.userBlockersUnsubscribesByUserId[blockerUserId]) state.userBlockersUnsubscribesByUserId[blockerUserId]()
      Vue.set(state.userBlockersUnsubscribesByUserId, blockerUserId, unsubscribe)
    },
    SET_BLOCKING_UNSUBSCRIBE(state, { blockingUserId, unsubscribe }) {
      if (state.userBlockingUnsubscribesByUserId[blockingUserId]) state.userBlockingUnsubscribesByUserId[blockingUserId]()
      Vue.set(state.userBlockingUnsubscribesByUserId, blockingUserId, unsubscribe)
    },
    INCREMENT_BLOCKER_WATCH_COUNT(state, { blockerUserId, increment = 1 }) {
      const newValue = (state.userBlockersWatchCountsByUserId[blockerUserId] || 0) + increment
      Vue.set(state.userBlockersWatchCountsByUserId, blockerUserId, newValue)
    },
    INCREMENT_BLOCKING_WATCH_COUNT(state, { blockingUserId, increment = 1 }) {
      const newValue = (state.userBlockingWatchCountsByUserId[blockingUserId] || 0) + increment
      Vue.set(state.userBlockingWatchCountsByUserId, blockingUserId, newValue)
    },
  },

  actions: {
    REINIT({ commit, dispatch, state }) {
      commit('REINIT')

      const { userBlockersWatchCountsByUserId, userBlockingWatchCountsByUserId } = state
      Object.keys(userBlockersWatchCountsByUserId).filter(key => userBlockersWatchCountsByUserId[key] > 0).forEach((userId) => {
        dispatch('START_BLOCKER_WATCHER_IF_NECESSARY', userId)
      })
      Object.keys(userBlockingWatchCountsByUserId).filter(key => userBlockingWatchCountsByUserId[key] > 0).forEach((userId) => {
        dispatch('START_BLOCKING_WATCHER_IF_NECESSARY', userId)
      })
    },

    START_BLOCKER_WATCHER_IF_NECESSARY({
      commit,
      state,
      rootState,
    }, blockerUserId) {
      const { signedInUserId } = rootState
      if (!signedInUserId) {
        commit('SET_BLOCKER_DATA', { blockerUserId, data: {} })
        return
      }

      const { signedInUser } = rootState
      if (signedInUser.adminPermission
        || signedInUser.newsroomAdminPermission
        || signedInUser.newsroomModeratePermission
        || (signedInUser.badgeList || []).includes('happsTeam')
      ) {
        commit('SET_BLOCKER_DATA', { blockerUserId, data: {} })
        return
      }

      if (state.userBlockersUnsubscribesByUserId[blockerUserId]) return

      const unsubscribe = watchDoc(USER_BLOCKED_USER_DOC_PATH(blockerUserId, signedInUserId), (blockDoc) => {
        const data = blockDoc.data() || {}
        commit('SET_BLOCKER_DATA', { blockerUserId, data })
      })
      commit('SET_BLOCKER_UNSUBSCRIBE', { blockerUserId, unsubscribe })
    },
    START_BLOCKING_WATCHER_IF_NECESSARY({ commit, state, rootState }, blockingUserId) {
      const { signedInUserId } = rootState
      if (!signedInUserId) {
        commit('SET_BLOCKING_DATA', { blockingUserId, data: {} })
        return
      }

      if (state.userBlockingUnsubscribesByUserId[blockingUserId]) return

      const unsubscribe = watchDoc(USER_BLOCKED_USER_DOC_PATH(signedInUserId, blockingUserId), (blockDoc) => {
        const data = blockDoc.data() || {}
        commit('SET_BLOCKING_DATA', { blockingUserId, data })
      })
      commit('SET_BLOCKING_UNSUBSCRIBE', { blockingUserId, unsubscribe })
    },
    WATCH_BLOCKER({ dispatch, commit }, blockerUserId) {
      commit('INCREMENT_BLOCKER_WATCH_COUNT', { blockerUserId })
      dispatch('START_BLOCKER_WATCHER_IF_NECESSARY', blockerUserId)
    },
    WATCH_BLOCKING({ dispatch, commit }, blockingUserId) {
      commit('INCREMENT_BLOCKING_WATCH_COUNT', { blockingUserId })
      dispatch('START_BLOCKING_WATCHER_IF_NECESSARY', blockingUserId)
    },
    UNWATCH_BLOCKER({ commit, state }, blockerUserId) {
      commit('INCREMENT_BLOCKER_WATCH_COUNT', { blockerUserId, increment: -1 })
      if (state.userBlockersWatchCountsByUserId[blockerUserId] <= 0) commit('SET_BLOCKER_UNSUBSCRIBE', { blockerUserId })
    },
    UNWATCH_BLOCKING({ commit, state }, blockingUserId) {
      commit('INCREMENT_BLOCKING_WATCH_COUNT', { blockingUserId, increment: -1 })
      if (state.userBlockingWatchCountsByUserId[blockingUserId] <= 0) commit('SET_BLOCKING_UNSUBSCRIBE', { blockingUserId })
    },

    async BLOCK_USER({
      dispatch,
    }, { blockingUserId, block, analyticsData }) {
      dispatch('analytics/LOG_EVENT', {
        name: block ? 'block_user_clicked' : 'unblock_user_clicked',
      }, { root: true })

      await dispatch('REQUIRE_AUTH_LEVEL_1', async () => {
        await performCloudFunction('blockUser', { blockingUserId, block, analyticsData })

        dispatch('analytics/LOG_EVENT', {
          name: block ? 'web_blocked_user' : 'web_unblocked_user',
          data: {
            user_id: blockingUserId,
            blockRecipientUserId: blockingUserId,
          },
        }, { root: true })
      }, { root: true })
    },
  },
}
