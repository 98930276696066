import store from '@/store'

// (module, items) or (items)
export default function mapGetSets(...args) {
  const items = args.length === 1 ? args[0] : args[1]
  const mod = args.length === 1 ? undefined : args[0]

  const result = {}

  items.forEach((item) => {
    result[item] = {
      get() {
        return mod ? store.state[mod][item] : store.state[item]
      },
      set(newValue) {
        if (mod) store.state[mod][item] = newValue
        else store.state[item] = newValue
      },
    }
  })

  return result
}
