import BaseInvitePage from '@/pages/join/BaseInvitePage.vue'
import WaitlistInvitePage from '@/pages/join/WaitlistInvitePage.vue'

export default [
  {
    path: ':inviteUsername',
    name: 'waitlistInvite',
    component: WaitlistInvitePage,
    meta: {
      partialAuthPermitted: true,
    },
  },
  {
    path: '',
    name: 'baseInvite',
    component: BaseInvitePage,
    meta: {
      partialAuthPermitted: true,
    },
  },
]
