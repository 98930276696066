//
//
//
//
//
//

import { getDoc } from '@happstv/shared/util/firebase/firestoreUtils'
import { AGENCY_TALENT_LIST_DOC_PATH } from '@happstv/shared/util/firebase/firestorePaths'

import PublicCreatorList from '@/components/agent/creator/mediaKit/PublicCreatorList.vue'

export default {
  name: 'PublicCreatorListPage',
  components: {
    PublicCreatorList,
  },
  data() {
    return {
      creatorList: undefined,
    }
  },
  computed: {
    creatorListId() { 
      return this.$route.params.creatorListId
    },
    listTitle() {
      return this.creatorList?.title
    },
  },
  watch: {
    listTitle: {
      immediate: true,
      handler(newValue) {
        if (newValue) this.$store.dispatch('UPDATE_PAGE_TITLE', { title: newValue })
        else this.$store.dispatch('UPDATE_PAGE_TITLE', { resetTitle: true })
      },
    },
  },
  async mounted() {
    const { creatorListId } = this
    const creatorListDoc = await getDoc(AGENCY_TALENT_LIST_DOC_PATH(creatorListId))
    
    if (!creatorListDoc.exists) {
      this.$router.goBack()
      return
    }
    this.creatorList = { id: creatorListDoc.id, ...(creatorListDoc.data() || {}) }
  },
  beforeDestroy() {
    this.$store.dispatch('UPDATE_PAGE_TITLE', { resetTitle: true })
  },
}
