/* eslint no-param-reassign: ["error", { "props": false }] */
import {
  getCol,
  watchCol,
  watchDoc,
  setDoc,
} from '@happstv/shared/util/firebase/firestoreUtils'
import {
  USERS_COL_PATH,
  USER_DOC_PATH,
  CHANNELS_COL_PATH,
} from '@happstv/shared/util/firebase/firestorePaths'

import {
  SUNDAY, // 'sunday'
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
} from '@happstv/shared/util/timeConstants'

import {
  PURPLE_THEME,
  BLUE_THEME,
  GREY_THEME,
  RED_THEME ,
  ORANGE_THEME,
  YELLOW_THEME,
} from '@/constants/userPageConstants'

const initState = () => ({
  communityUser: undefined,
  communityUserUnsubscribe: undefined,
  communityUserLatestChannel: undefined,
  loading: false,
  latestChannelLoading: false,
  userStartedFollowFlow: false,
  smsDialogOpen: false,
})

export default {
  namespaced: true,

  state: initState(),

  getters: {
    userPageTheme(state) {
      return state.communityUser?.userPageTheme
    },
    userPageBackgroundPath(_, getters) {
      const { userPageTheme } = getters

      switch(userPageTheme) {
        case BLUE_THEME:
          return '/img/themeBackgrounds/background-blue.jpg'
        case GREY_THEME:
          return '/img/themeBackgrounds/background-grey.jpg'
        case RED_THEME:
          return '/img/themeBackgrounds/background-red.jpg'
        case ORANGE_THEME:
          return '/img/themeBackgrounds/background-orange.jpg'
        case YELLOW_THEME:
          return '/img/themeBackgrounds/background-yellow.jpg'
        case PURPLE_THEME:
          return '/img/themeBackgrounds/background-purple.jpg'
        default:
          return '/img/themeBackgrounds/background-purple.jpg'
      }
    },
    userWasLiveTagGradient(_, getters) {
      const { userPageTheme } = getters
      switch(userPageTheme) {
        case BLUE_THEME:
          return 'linear-gradient(110.08deg, #666985 0.7%, #7C7BA0 97.57%)'
        case GREY_THEME:
          return 'linear-gradient(110.08deg, #929292 0.7%, #7D747C 97.57%)'
        case RED_THEME:
          return 'linear-gradient(110.08deg, #915A67 0.7%, #9D4646 97.57%)'
        case ORANGE_THEME:
          return 'linear-gradient(110.08deg, #93623E 0.7%, #B98537 97.57%)'
        case YELLOW_THEME:
          return 'linear-gradient(110.08deg, #B0AA6C 0.7%, #B99B31 97.57%)'
        case PURPLE_THEME:
          return 'linear-gradient(110.08deg, #806685 0.7%, #A07B9A 97.57%)'
        default:
          return 'linear-gradient(110.08deg, #806685 0.7%, #A07B9A 97.57%)'
      }
    },
    userNumericSchedule(state) {
      const unsortedSchedule = state.communityUser?.userCurrentSchedule || []

      const map = {
        [SUNDAY]: 0,
        [MONDAY]: 1,
        [TUESDAY]: 2,
        [WEDNESDAY]: 3,
        [THURSDAY]: 4,
        [FRIDAY]: 5,
        [SATURDAY]: 6,
      }

      return unsortedSchedule.map(item => map[item]).sort()
    },
    userScheduleEnabled(state) {
      return state.communityUser?.scheduleEnabled
    },
  },

  mutations: {
    REINIT(state) {
      Object.assign(state, initState())
    },
    SET_COMMUNITY_USER(state, payload) {
      state.communityUser = payload
    },
    SET_COMMUNITY_USER_LATEST_CHANNEL(state, payload) {
      state.communityUserLatestChannel = payload
    },
    SET_COMMUNITY_USER_UNSUBSCRIBE(state, payload) {
      state.communityUserUnsubscribe = payload
    },
    SET_COMMUNITY_USER_LATEST_CHANNEL_UNSUBSCRIBE(state, payload) {
      state.communityUserLatestChannelUnsubscribe = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_LATEST_CHANNEL_LOADING(state, payload) {
      state.latestChannelLoading = payload
    },
    SET_USER_STARTED_FOLLOW_FLOW(state, payload) {
      state.userStartedFollowFlow = payload
    },
    SET_SMS_DIALOG_OPEN(state, payload) {
      state.smsDialogOpen = payload
    },
  },

  actions: {
    REINIT({ commit, state }) {
      if (state.communityUserUnsubscribe) state.communityUserUnsubscribe()
      if (state.communityUserLatestChannelUnsubscribe) state.communityUserLatestChannelUnsubscribe()
      commit('REINIT')
    },

    PROCESS_COMMUNITY_USER_DOC({ commit }, userDoc) {
      if (!userDoc) {
        commit('SET_COMMUNITY_USER', undefined)
        return
      }

      const userData = userDoc.data() || {}
      if (!userData.approvedForStarlight || userData.deactivated) {
        commit('SET_COMMUNITY_USER', undefined)
        return
      }

      commit('SET_COMMUNITY_USER', { id: userDoc.id, ...(userDoc.data() || {}) })
    },

    async INIT_COMMUNITY_USER({ dispatch, commit }, { username }) {
      if (!username) return

      commit('SET_LOADING', true)

      let unsubscribed = false

      const interimUnsubscribe = () => {
        unsubscribed = true
      }

      commit('SET_COMMUNITY_USER_UNSUBSCRIBE', interimUnsubscribe)

      const userSnap = await getCol(USERS_COL_PATH, q => q.where('lowercaseUsername', '==', username.toLowerCase()))
      if (unsubscribed) return

      const [resolvedUserDoc] = userSnap.docs
      dispatch('PROCESS_COMMUNITY_USER_DOC', resolvedUserDoc)
      commit('SET_LOADING', false)

      if (!resolvedUserDoc) return
      const resolvedUserId = resolvedUserDoc.id

      const communityUserUnsubscribe = watchDoc(USER_DOC_PATH(resolvedUserId), (userDoc) => {
        dispatch('PROCESS_COMMUNITY_USER_DOC', userDoc)
      })
      commit('SET_COMMUNITY_USER_UNSUBSCRIBE', communityUserUnsubscribe)
    },
    async INIT_COMMUNITY_USER_LIVE({ dispatch, commit }, { username }) {
      if (!username) return

      commit('SET_LATEST_CHANNEL_LOADING', true)

      let unsubscribed = false

      const interimUnsubscribe = () => {
        unsubscribed = true
      }

      commit('SET_COMMUNITY_USER_LATEST_CHANNEL_UNSUBSCRIBE', interimUnsubscribe)

      const userSnap = await getCol(USERS_COL_PATH, q => q.where('lowercaseUsername', '==', username.toLowerCase()))
      if (unsubscribed) {
        commit('SET_LATEST_CHANNEL_LOADING', false)
        return
      }

      const [resolvedUserDoc] = userSnap.docs
      dispatch('PROCESS_COMMUNITY_USER_DOC', resolvedUserDoc)

      if (!resolvedUserDoc) {
        commit('SET_LATEST_CHANNEL_LOADING', false)
        return
      }
      const resolvedUserId = resolvedUserDoc.id

      const communityUserLatestChannelUnsubscribe = await watchCol(CHANNELS_COL_PATH, q => q.where('joinedUsers', 'array-contains', resolvedUserId).orderBy('originVideoStartedDate', 'desc').limit(1), (channelsSnap) => {
        const [channelDoc] = channelsSnap.docs
        if (!channelDoc) {
          commit('SET_COMMUNITY_USER_LATEST_CHANNEL', undefined)
          commit('SET_LATEST_CHANNEL_LOADING', false)
          return
        }

        commit('SET_COMMUNITY_USER_LATEST_CHANNEL', { id: channelDoc.id, ...(channelDoc.data() || {}) })
        commit('SET_LATEST_CHANNEL_LOADING', false)
      })
      commit('SET_COMMUNITY_USER_LATEST_CHANNEL_UNSUBSCRIBE', communityUserLatestChannelUnsubscribe)
    },
    async SET_COLOR(_, {color, userId}) {
      await setDoc(USER_DOC_PATH(userId), { userPageTheme: color })
    },
  },
}
