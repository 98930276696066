import Vue from 'vue'

const listeners = []

function getCallbackParams({ scroll = false, resize = false }) {
  const windowRef = window
  const {
    scrollY,
    scrollX,
    innerHeight,
    innerWidth,
  } = windowRef

  const documentRef = document
  const documentHeight = documentRef.body.clientHeight
  const documentWidth = documentRef.body.clientWidth

  return {
    scroll,
    resize,
    windowRef,
    scrollY,
    scrollX,
    innerHeight,
    innerWidth,
    documentRef,
    documentHeight,
    documentWidth,
  }
}


let isFiringEvent = false
let shouldFireScrollEvent = false
let shouldFireResizeEvent = false

function fireListenerCallbacks() {
  const scroll = shouldFireScrollEvent
  const resize = shouldFireResizeEvent

  const callbackParams = getCallbackParams({ scroll, resize })

  listeners.forEach((listener) => {
    if ((scroll && listener.scroll) || (resize && listener.resize)) {
      listener.callback(callbackParams)
    }
  })
}

function fireEvent({ scroll = false, resize = false } = {}) {
  if (scroll) shouldFireScrollEvent = true
  if (resize) shouldFireResizeEvent = true

  if (isFiringEvent) return
  if (!shouldFireScrollEvent && !shouldFireResizeEvent) return

  isFiringEvent = true

  fireListenerCallbacks()

  shouldFireResizeEvent = false
  shouldFireScrollEvent = false

  setTimeout(() => {
    isFiringEvent = false
    fireEvent()
  }, 300)
}

window.addEventListener('scroll', () => fireEvent({ scroll: true }))
window.addEventListener('resize', () => fireEvent({ resize: true }))


export function registerThrottledEventListener({ scroll = false, resize = false, immediate }, callback) {
  // if (immediate) Vue.nextTick(() => callback(getCallbackParams({ scroll, resize })))

  const listener = {
    scroll,
    resize,
    callback,
  }
  listeners.push(listener)

  if (immediate) Vue.nextTick(() => fireEvent({ scroll, resize }))

  return listener
}

export function unregisterThrottledEventListener(listener) {
  const index = listeners.indexOf(listener)
  if (index >= 0) listeners.splice(index, 1)
}
