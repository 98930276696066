
import firebase from 'firebase/app'

export default async function performCloudFunction(functionName, params = {}, options) {
  try {
    return (await firebase.functions().httpsCallable(functionName, options)(params) || {}).data || {}
  } catch (e) { /* eslint-disable no-console */
    console.error(e) /* eslint-enable no-console */
    return {}
  }
}
