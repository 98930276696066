var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "publicCreatorListPage"
  }, [_c('PublicCreatorList', _vm._b({
    staticStyle: {
      "flex": "1"
    }
  }, 'PublicCreatorList', {
    creatorList: _vm.creatorList
  }, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }