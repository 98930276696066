import Vue from 'vue'

import { watchDoc } from '@happstv/shared/util/firebase/firestoreUtils'
import { USER_NOTIFICATION_SETTINGS_DOC_PATH } from '@happstv/shared/util/firebase/firestorePaths'

const AreYouSureDialog = () => import('@/dialogs/universal/AreYouSureDialog.vue')

const initState = () => ({
  pushInitialized: false,
  pushPossible: false,
  pushAuthState: false, // undefined, false, or true
  pushSubscriptionState: false,

  notificationSettings: undefined,
  notificationSettingsUnsubscribe: undefined,
})

function oneSignalInstance() {
  if (!window.OneSignal) window.OneSignal = window.OneSignal || []
  return window.OneSignal
}

function oneSignalWrapper(osFunction) {
  oneSignalInstance().push(() => { osFunction(oneSignalInstance()) })
}

let nativePromptCallbacks = []

export default {
  namespaced: true,

  state: initState(),

  mutations: {
    REINIT(state) {
      Object.assign(state, initState())
    },
    SET_PUSH_INITIALIZED(state, value) {
      state.pushInitialized = value
    },
    SET_PUSH_POSSIBLE(state, value) {
      state.pushPossible = value
    },
    SET_PUSH_AUTH_STATE(state, value) {
      state.pushAuthState = value
    },
    SET_PUSH_SUBSCRIPTION_STATE(state, value) {
      state.pushSubscriptionState = value
    },
    REINIT_NOTIFICATIONS_SETTINGS(state) {
      if (state.notificationSettingsUnsubscribe) state.notificationSettingsUnsubscribe()
      state.notificationSettingsUnsubscribe = undefined
      state.notificationSettings = undefined
    },
    SET_NOTIFICATIONS_SETTINGS_UNSUBSCRIBE(state, value) {
      state.notificationSettingsUnsubscribe = value
    },
    SET_NOTIFICATIONS_SETTINGS(state, value) {
      state.notificationSettings = value
    },
  },

  actions: {
    REINIT({ commit }) {
      commit('REINIT')
    },
    INIT_PUSH_NOTIFICATIONS({ state, commit, dispatch }) {
      if (state.pushInitialized) return

      let appId
      switch (window.location.hostname) {
        case 'happs.co':
          appId = 'e69c6030-e5d4-47d3-9477-7218a9e41ea9'
          break
        case 'happs.dev':
          appId = 'bc1d16b3-219e-4e5f-9073-4c56d385edce'
          break
        case 'localhost':
          appId = '7bbc5794-be30-44e2-a6c3-5d3d3622d359'
          break
        default: return
      }

      oneSignalWrapper(async (os) => {
        os.on('subscriptionChange', () => {
          dispatch('UPDATE_AUTH_STATE', true)
          dispatch('UPDATE_USER_ID')
        })

        os.on('notificationPermissionChange', async (permissionChange) => {
          nativePromptCallbacks.forEach(callback => callback())
          nativePromptCallbacks = []

          if (permissionChange.to === 'granted') {
            await dispatch('UPDATE_AUTH_STATE', true)
            setTimeout(() => {
              dispatch('SET_NOTIFICATION_SUBSCRIPTION', true)
            }, 1000)
          }
        })

        os.init({
          appId,
          autoResubscribe: true,
          persistNotification: false,
          notificationClickHandlerMatch: 'origin',
          welcomeNotification: {
            title: 'Happs',
            message: "You're all set! 🎉",
          },
        })
        await dispatch('UPDATE_AUTH_STATE', true)

        commit('SET_PUSH_INITIALIZED', true)

        dispatch('UPDATE_USER_ID')
      })
    },
    UPDATE_USER_ID({ state, rootState }) {
      if (!state.pushInitialized || rootState.waitingAuth) return
      const userId = (rootState.signedInUser || {}).id
      if (userId) oneSignalInstance().setExternalUserId(userId)
      else oneSignalInstance().removeExternalUserId()
    },
    async UPDATE_AUTH_STATE({ state, commit }, pushInitialized) {
      if (!state.pushInitialized && !pushInitialized) return

      if (!oneSignalInstance().isPushNotificationsSupported()) {
        commit('SET_PUSH_POSSIBLE', false)
        commit('SET_PUSH_AUTH_STATE', false)
        commit('SET_PUSH_SUBSCRIPTION_STATE', false)
        return
      }

      commit('SET_PUSH_POSSIBLE', true)

      const subscriptionState = await oneSignalInstance().isPushNotificationsEnabled()
      commit('SET_PUSH_SUBSCRIPTION_STATE', subscriptionState)

      const permissionState = await oneSignalInstance().getNotificationPermission()

      switch (permissionState) {
        case 'default':
          commit('SET_PUSH_AUTH_STATE', undefined)
          break
        case 'granted':
          commit('SET_PUSH_AUTH_STATE', true)
          break
        case 'denied':
          commit('SET_PUSH_AUTH_STATE', false)
          break
        default: /* eslint-disable no-console */
          console.log('Unknown push permission: ', permissionState) /* eslint-enable no-console */
          break
      }
    },
    REGISTER_FOR_NOTIFICATIONS({ state, commit }, { callback } = {}) {
      if (!state.pushInitialized) return
      if (callback) nativePromptCallbacks.push(callback)
      commit('SET_PUSH_AUTH_STATE', undefined)
      oneSignalInstance().showNativePrompt()
    },
    async SET_NOTIFICATION_SUBSCRIPTION({ state, dispatch }, value) {
      if (!state.pushInitialized) return
      if (state.pushAuthState) {
        await oneSignalInstance().setSubscription(value)
      } else dispatch('REGISTER_FOR_NOTIFICATIONS')
    },
    REINIT_NOTIFICATIONS_SETTINGS({ commit }) {
      commit('REINIT_NOTIFICATIONS_SETTINGS')
    },
    INIT_NOTIFICATIONS_SETTINGS({ rootState, commit }) {
      const userId = (rootState.signedInUser || {}).id
      if (!userId) return

      const notificationSettingsUnsubscribe = watchDoc(USER_NOTIFICATION_SETTINGS_DOC_PATH(userId), (notificationSettingsDoc) => {
        const notificationSettings = notificationSettingsDoc.data() || {}
        commit('SET_NOTIFICATIONS_SETTINGS', notificationSettings)
      })
      commit('SET_NOTIFICATIONS_SETTINGS_UNSUBSCRIBE', notificationSettingsUnsubscribe)
    },

    SHOW_PUSH_PERMISSION_DIALOG({ dispatch }) {
      Vue.component('AreYouSureDialog', AreYouSureDialog)

      dispatch('overlays/ADD_FULLSCREEN_POPUP', {
        component: AreYouSureDialog,
        params: {
          popupPurpose: 'pushNotifications.enable',
          title: 'Enable Push Notifications',
          text: 'Happs will send push notifications to this device whenever someone interacts meaningfully with your content.<br><br>You can always change your notification preferences in the notification settings menu.',
          noText: 'MAYBE LATER',
          yesText: 'ENABLE',
          borderColor: 'var(--happsPink)',
          yesFunction() {
            return new Promise((resolve) => {
              dispatch('REGISTER_FOR_NOTIFICATIONS', { callback: resolve })
            })
          },
        },
      }, { root: true })
    },
  },
}
