
import {
  getCol,
  getDoc,
  getColGroup,
} from '@happstv/shared/util/firebase/firestoreUtils'
import {
  USERS_COL_PATH,
  USER_DOC_PATH,
  USER_EXTRAS_GROUP_NAME,
  USER_PRIVATE_DATA_DOC_ID,
  USER_PRIVATE_DATA_DOC_PATH,
} from '@happstv/shared/util/firebase/firestorePaths'
import { isDev, filterObjectKeys } from '@happstv/shared/util/utils'

import algoliasearch from 'algoliasearch/lite'

import performCloudFunction from '@/util/firebase/performCloudFunction'

const algoliaClientId = 'T5ZZOBOTPZ'
const algoliaClientSearchKey = '629cd1eb58a21b088e1a0d870fa991d2'

const getAlgoliaClient = async () => algoliasearch(algoliaClientId, algoliaClientSearchKey)

const COL_USERS = 'users'
const COL_POSTS = 'posts'
const COL_COMMENTS = 'comments'
const COL_STORIES = 'stories'
const COL_TEAMS = 'teams'

const supportedAlgoliaCollections = [COL_USERS, COL_POSTS, COL_COMMENTS, COL_STORIES, COL_TEAMS]

function indexName(col) {
  return `${!isDev ? 'prod' : 'dev'}-${col}`
}

const SEARCH_COL_BY_SEARCH_KEY = {
  users: USERS_COL_PATH,
}

const RESULT_FILTER_FUNCTION_BY_SEARCH_KEY = {
  users(resultItem, signedInUser) {
    if (signedInUser.adminPermission) return resultItem // pass all keys through

    if (resultItem.deactivated || resultItem.bannedFromCommunity || resultItem.bannedFromCommunityShadow) return undefined

    return filterObjectKeys(resultItem, [
      'fullName',
      'profilePhotoUrl',
      'profileThumbUrl',
      'profession',
      'city',
      'username',
      'userFollowingCount',
      'userFollowerCount',
      'totalFollowerCount',
      'externalFollowerCount',
      'subscribingCount',
      'subscriberCount',
      'sentVisiblePostCount',
      'sentVisibleBroadcastCount',
      'sentCommentCount',
      'teamIds',
      'badgeList',
    ])
  },
}


async function performKeySearchRecursive(
  queryCol,
  resultFilterFunction,
  signedInUser,
  queryLow,
  queryHigh,
  remainingKeys,
  limit,
) {
  const [key, ...nextKeys] = remainingKeys || []

  if (!key) {
    return []
  }

  if (queryCol === USERS_COL_PATH && ['phone', 'email', 'lowercaseEmail'].includes(key)) {
    const resultsSnap = await getColGroup(USER_EXTRAS_GROUP_NAME, q => q.orderBy(key, 'asc')
      .where(key, '>=', queryLow)
      .where(key, '<', queryHigh)
      .where('id', '==', USER_PRIVATE_DATA_DOC_ID)
      .limit(limit))

    const results = (await Promise.all(
      (resultsSnap.docs || []).map(async (privateDataDoc) => {
        const [, userId] = privateDataDoc.ref.path.split('/')
        const userDoc = await getDoc(USER_DOC_PATH(userId))

        const filteredData = resultFilterFunction({ ...(userDoc.data() || {}), ...(privateDataDoc.data() || {}) }, signedInUser)
        if (!filteredData) return undefined

        return { ...filteredData, id: userId }
      }),
    )).filter(r => r)

    if (results.length >= limit) {
      return results
    }

    return results.concat(await performKeySearchRecursive(
      queryCol,
      resultFilterFunction,
      signedInUser,
      queryLow,
      queryHigh,
      nextKeys,
      limit - results.length,
    ))
  }

  const resultsSnap = await getCol(queryCol, q => q.orderBy(key, 'asc').where(key, '>=', queryLow).where(key, '<', queryHigh).limit(limit))

  let resultsDocs = (resultsSnap.docs || []).map(resultDoc => ({ resultDoc }))

  if (queryCol === USERS_COL_PATH && (signedInUser || {}).adminPermission) {
    resultsDocs = await Promise.all(
      resultsDocs.map(async ({ resultDoc }) => ({ resultDoc, privateDataDoc: await getDoc(USER_PRIVATE_DATA_DOC_PATH(resultDoc.id)) })),
    )
  }

  const results = resultsDocs
    .map((docs) => {
      const filteredData = resultFilterFunction(Object.values(docs).reduce((result, doc) => ({ ...result, ...(doc.data() || {}) }), {}), signedInUser)
      if (!filteredData) return undefined

      return { ...filteredData, id: docs.resultDoc.id }
    }).filter(r => r)

  if (results.length >= limit) {
    return results
  }

  return results.concat(await performKeySearchRecursive(
    queryCol,
    resultFilterFunction,
    signedInUser,
    queryLow,
    queryHigh,
    nextKeys,
    limit - results.length,
  ))
}


export default {
  namespaced: true,

  state: {},

  actions: {
    async PERFORM_KEY_SEARCH({ rootState }, {
      query,
      key,
      search,
      queryCol,
      resultFilterFunction,
    }) {
      const queryHigh = (query.substring(0, query.length - 1) + String.fromCharCode(query.charCodeAt(query.length - 1) + 1))
      const { signedInUser = {} } = rootState

      let results = await performKeySearchRecursive(
        queryCol,
        resultFilterFunction,
        signedInUser,
        query,
        queryHigh,
        search.keysToSearch || [],
        search.maxResultCount || 10,
      )

      results = results.filter((item, index, self) => self.findIndex(({ id }) => id === item.id) === index)

      return { [key]: results }
    },

    async PERFORM_SIMPLE_SEARCH({ dispatch }, { query, searches }) {
      const searchParams = Object.keys(searches).map(key => ({
        key,
        search: searches[key],
        queryCol: SEARCH_COL_BY_SEARCH_KEY[key],
        resultFilterFunction: RESULT_FILTER_FUNCTION_BY_SEARCH_KEY[key],
      }))

      if (searchParams.some(({ queryCol, resultFilterFunction }) => !queryCol || !resultFilterFunction)) {
        return await performCloudFunction('performSimpleSearch', { query, searches }) || {}
      }

      const resultArray = await Promise.all(
        searchParams.map(params => dispatch('PERFORM_KEY_SEARCH', { query, ...params })),
      )
      const results = Object.assign({}, ...resultArray)
      return { results }
    },

    async PERFORM_COMPLEX_SEARCH({ rootState }, { query, searches, includeAdminOnly }) {
      if (includeAdminOnly && !(rootState.signedInUser || {}).newsroomAdminPermission) return {}

      const collections = Object.keys(searches).filter(col => supportedAlgoliaCollections.includes(col))

      const queries = collections.map((col) => {
        const searchSettings = searches[col] || {}

        const params = {
          hitsPerPage: searchSettings.maxResultCount || 10,
        }

        if (!includeAdminOnly) params.filters = 'NOT adminOnly:true'
        if (searchSettings.keysToSearch) params.restrictSearchableAttributes = searchSettings.keysToSearch

        return {
          indexName: indexName(col),
          query,
          params,
        }
      })

      const results = {}

      if (queries.length) {
        try {
          const searchResponse = await (await getAlgoliaClient()).search(queries)

          await Promise.all(
            searchResponse.results.map(async (result, i) => {
              const collection = collections[i]
              results[collection] = await Promise.all(
                (result.hits || []).map(async (hit) => {
                  const item = { id: hit.objectID, ...hit }

                  switch (collection) {
                    case COL_POSTS: {
                      if (!item.postUserId) break
                      const postUserDoc = await getDoc(USER_DOC_PATH(item.postUserId))
                      const postUserData = postUserDoc.data() || {}
                      item.postUser = filterObjectKeys(postUserData, ['fullName', 'profilePhotoUrl', 'profession', 'username'])
                      break
                    }
                    case COL_COMMENTS: {
                      if (!item.commentUserId) break
                      const commentUserDoc = await getDoc(USER_DOC_PATH(item.commentUserId))
                      const commentUserData = commentUserDoc.data() || {}
                      item.commentUser = filterObjectKeys(commentUserData, ['fullName', 'profilePhotoUrl', 'profession', 'username'])
                      break
                    }
                    case COL_USERS: {
                      return filterObjectKeys(item, [
                        'id',
                        'fullName',
                        'profilePhotoUrl',
                        'profileThumbUrl',
                        'profession',
                        'bio',
                        'username',
                        'badgeList',
                      ])
                    }
                    default: break
                  }

                  return item
                }),
              )
            }),
          )
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Algolia Search Error: ', e)
          return {}
        }
      }

      return { results }
    },
  },
}
